import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bae-header',
  templateUrl: './bae-header.component.html',
  styleUrls: ['./bae-header.component.css'],
})
export class BaeHeaderComponent implements OnInit {
  imagePath: any;

  constructor() {}

  ngOnInit(): void {
    let urlArray = window.location.href.split('.com');
    this.imagePath = urlArray.length == 1 ? 'il' : urlArray[0].slice(-2);
  }

  getSvgLogoPath(imagePath:string): string {
    switch (this.imagePath) {
      case 'il':
        return '../../../assets/images/bcbsil-logo.svg';
      case 'mt':
        return '../../../assets/images/bcbsmt-logo.svg';
      case 'nm':
        return '../../../assets/images/bcbsnm-logo.svg';
      case 'ok':
        return '../../../assets/images/bcbsok-logo.svg';
      case 'tx':
        return '../../../assets/images/bcbstx-logo.svg';
      default:
        return '../../../assets/images/bcbsil-logo.svg';
    }
  }
}
