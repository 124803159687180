<!-- <header class="fiber-mt-5 fiber-mb-5 fiber-mr-3">
    <div class="fiber-grid  resp-margin">
        <div class="fiber-grid__row">
            <div class="fiber-grid__col  fiber-grid__col--xs-6 fiber-grid__col--md-5">
                <div class="fiber-bcbs-logo">
                    <img style="height: 68px;" src="assets/blue_access_for_producers.png">
                </div>    
            </div>
        </div>
    </div>
</header> -->
<!-- <header class="fiber-mt-5 fiber-mb-5 fiber-mr-3">
    <div class="fiber-grid  resp-margin">
        <div class="fiber-grid__row">
            <div class="fiber-grid__col  fiber-grid__col--xs-6 fiber-grid__col--md-5">
                <div class="fiber-bcbs-logo">
                    <img style="height: 68px;" src="assets/blue_access_for_producers.png">
                </div>    
            </div>
        </div>
    </div>
</header> -->
<header class="fiber-mt-5 fiber-mb-5 fiber-mr-3">
  <div class="fiber-grid resp-margin">
    <div class="fiber-grid__row">
      <div class="fiber-grid__col fiber-grid__col--xs-6 fiber-grid__col--md-5">
        <div class="fiber-bcbs-logo">
          <span class="fiber-bcbs-logo__sr-only"
            >Blue Cross Blue Shield of Illinois
          </span>

          <img
            [src]="getSvgLogoPath(imagePath)"
            alt="Logo image"
            class="fiber-bcbs-logo--logo-blue"
          />

          <!-- <svg  *ngIf="imagePath==='il'"xmlns="imagePath" height="100%"
                        viewBox="0 0 222.1 36.9" aria-hidden="true">
                        <g class="fiber-bcbs-logo--logo-blue">
                            <path
                                d="M29.7,34.8c0.3,0,0.7,0,0.7-0.4c0-0.3-0.3-0.4-0.5-0.4h-0.6v0.7L29.7,34.8L29.7,34.8z M30.6,35.9h-0.3L29.7,35 h-0.5v0.9H29v-2h0.9c0.5,0,0.7,0.2,0.7,0.5c0,0.4-0.3,0.6-0.6,0.6L30.6,35.9L30.6,35.9z M31.3,34.9c0-0.9-0.6-1.6-1.5-1.6 s-1.5,0.7-1.5,1.6s0.6,1.6,1.5,1.6C30.6,36.5,31.3,35.8,31.3,34.9L31.3,34.9z M27.9,34.9c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8 s-0.8,1.8-1.8,1.8S27.9,35.9,27.9,34.9L27.9,34.9z">
                            </path>
                            <path
                                d="M64,34.8c0.3,0,0.7,0,0.7-0.4c0-0.3-0.3-0.4-0.5-0.4h-0.5v0.7L64,34.8L64,34.8z M65,35.9h-0.3L64.1,35h-0.4 v0.9h-0.3v-2h0.9c0.5,0,0.7,0.2,0.7,0.5c0,0.4-0.3,0.6-0.6,0.6L65,35.9L65,35.9z M65.6,34.9c0-0.9-0.6-1.6-1.5-1.6 c-0.9,0-1.5,0.7-1.5,1.6s0.7,1.6,1.5,1.6C65,36.5,65.6,35.8,65.6,34.9L65.6,34.9z M62.3,34.9c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8 s-0.8,1.8-1.8,1.8C63.1,36.7,62.3,35.9,62.3,34.9L62.3,34.9z">
                            </path>
                            <polygon
                                points="26.1,36.5 26.1,26.5 36.2,26.5 36.2,10.4 26.1,10.4 26.1,0.4 10.1,0.4 10.1,10.4 0,10.4 0,26.5  10.1,26.5 10.1,36.5 	">
                            </polygon>
                            <path
                                d="M56,36.9c6.9-3.8,11.1-9.9,13.3-16.6c2.2-7,1.9-15.2-0.7-20.3c-4,2-8,3-12.6,3s-8.6-1-12.6-3 c-2.6,5.1-3,13.4-0.7,20.3C44.8,27,49.1,33.1,56,36.9L56,36.9L56,36.9z">
                            </path>
                        </g>
                        <g class="fiber-bcbs-logo--logo-white">
                            <path
                                d="M8.8,15l6.7,0.6c1.3,0.2,1.4,1.4,1.1,2.1L12,26.3l0,0c-2.3-1.8-3.8-4.6-3.8-7.8C8.2,17.2,8.4,16.1,8.8,15 L8.8,15L8.8,15z">
                            </path>
                            <path
                                d="M27.4,15l-6.7,0.6c-1.3,0.2-1.4,1.4-1.1,2.1l4.6,8.6l0,0c2.3-1.8,3.8-4.6,3.8-7.8C28.1,17.2,27.8,16.1,27.4,15 L27.4,15L27.4,15z">
                            </path>
                            <path
                                d="M18.1,9.8c1.2,0,1.5,1.3,1,2c-0.5,0.6-0.6,0.8-0.5,1.4c0,0.5,0.5,1.1,1.5,1.1h7.1c-1.6-3.4-5-5.7-9-5.7 s-7.4,2.3-9,5.7h7.1c1,0,1.5-0.6,1.5-1.1c0-0.6-0.1-0.8-0.5-1.4C16.6,11.1,17,9.8,18.1,9.8L18.1,9.8z">
                            </path>
                            <path
                                d="M18.1,28.4c2,0,3.9-0.6,5.5-1.6l0,0l-4.9-7.3c-0.3-0.5-0.9-0.5-1.2,0l-4.8,7.3l0,0 C14.2,27.8,16.1,28.4,18.1,28.4">
                            </path>
                            <path
                                d="M56,33.8c5.7-3.5,8.7-8.6,10.7-14.3c1.7-5.1,2.2-11,0.2-15.7c-2.9,1-4.5,1.3-6.1,1.5c-1.4,0.2-2.7,0.4-4.9,0.4 l0,0c-2.1,0-3.4-0.2-4.9-0.4c-1.6-0.2-3.2-0.5-6.1-1.5c-1.9,4.6-1.5,10.6,0.3,15.7C47.2,25.2,50.3,30.3,56,33.8L56,33.8L56,33.8z">
                            </path>
                        </g>
                        <g class="fiber-bcbs-logo--logo-blue">
                            <path
                                d="M9.8,16.4l5,0.5c0.4,0,0.4,0.2,0.3,0.4L11.6,24c-1.3-1.5-2.1-3.4-2.1-5.6C9.6,17.8,9.7,17.1,9.8,16.4L9.8,16.4 z">
                            </path>
                            <path
                                d="M15.8,10.2c-0.5,0.8-0.1,1.9,0.4,2.5c0.1,0.1,0,0.2,0,0.2h-4.5C12.7,11.7,14.1,10.7,15.8,10.2L15.8,10.2z">
                            </path>
                            <path
                                d="M26.4,16.4l-5,0.5c-0.4,0-0.4,0.2-0.3,0.4l3.5,6.7c1.3-1.5,2.1-3.4,2.1-5.6C26.7,17.8,26.6,17.1,26.4,16.4 L26.4,16.4z">
                            </path>
                            <path
                                d="M20.5,10.2c0.5,0.8,0.1,1.9-0.4,2.5c-0.1,0.1,0,0.2,0,0.2h4.5C23.6,11.7,22.1,10.7,20.5,10.2L20.5,10.2z">
                            </path>
                            <path
                                d="M56,32.4c3.9-2.4,8.2-7.9,10-15.2c0.9-3.8,1.2-8.2,0-11.6c-1.7,0.5-3.3,0.8-5,1.1c-1.7,0.2-3.3,0.4-4.9,0.4 l0,0c-1.6,0-3.3-0.2-4.9-0.4c-1.7-0.2-3.3-0.6-5-1.1c-1.2,3.4-1,7.8,0,11.6C47.8,24.5,52.1,30,56,32.4L56,32.4L56,32.4z">
                            </path>
                            <path
                                d="M18,21.2c0.1-0.1,0.2-0.1,0.3,0l3.3,5.1c-1.1,0.5-2.2,0.7-3.5,0.7c-1.2,0-2.4-0.3-3.5-0.7L18,21.2 c0.1-0.1,0.2-0.1,0.3,0">
                            </path>
                        </g>
                        <g class="fiber-bcbs-logo--logo-white">
                            <path
                                d="M52,9.6c-1.3,0.7-1.8,2.3-1.2,3.6c0.5,1.1,1.5,1.9,2.7,2.2c0.6,0.2,1.2,0.2,1.8,0.2c0.7,0,1.4,0.1,2.1,0.2 c0.2,0,0.3,0.1,0.4,0.3c0.2,0.4,0,0.7-0.4,0.9c0,0.6-0.1,1.9-0.1,1.9s3-0.3,2.5-3c-0.1-0.7-0.6-1.3-1.2-1.6 c-1.5-0.8-3.2-0.4-4.7-0.9c-0.5-0.2-1.3-0.4-1.4-1c-0.1-0.2,0-0.7,0.2-0.8c0.4-0.1,0.8,0.2,1.4,0.3c0.4,0.1,0.7,0.1,1,0 c0.1,0,0.3-0.1,0.3-0.2c0-0.1-0.2-0.1-0.2-0.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.3-0.1-0.6-0.3-0.9-0.4c0.3-0.1,0.8-0.1,1.1,0 c0.2,0,1,0,1.2,0c0,0,0.2-0.1,0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.3-0.4-0.6-0.7-0.7C54.2,9.2,53,9.1,52,9.6L52,9.6z">
                            </path>
                            <path
                                d="M57.1,19.4v-3.2c0,0-0.4-0.1-1-0.1c-0.7-0.1-1.1,0-1.1,0v0.3l-0.4,0.2c0,0-0.1,0.2-0.1,0.3 c0.3,0.8,0.7,1.5,0.8,2.4C55.9,19.3,56.4,19.3,57.1,19.4L57.1,19.4z">
                            </path>
                            <path
                                d="M53.3,18.9c-0.1,1.2,0.7,1.9,1.6,2.1c0.7,0.2,1.4,0.2,2.1,0.3c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.1,0.2,0.2 s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.3,0.2v1.5c0,0,0.6,0,1.1-0.3s0.7-0.6,0.9-1.1c0.1-0.5,0-1-0.3-1.5c-0.2-0.3-0.7-0.6-1.1-0.7 c-0.9-0.3-1.8-0.3-2.8-0.4c-0.1,0-0.3-0.1-0.3-0.3s0.2-0.3,0.2-0.3s-0.1-0.4-0.2-0.7c-0.1-0.3-0.3-0.6-0.4-0.8 C53.8,17.7,53.4,18.2,53.3,18.9L53.3,18.9z">
                            </path>
                            <path d="M57,21.7v2.7l-0.7-0.3l-0.6-0.3v-2.2C56.2,21.6,56.5,21.6,57,21.7L57,21.7z">
                            </path>
                            <path
                                d="M54,23.1c-0.4,0.9,0.1,1.7,0.8,2.1c0.4,0.2,0.9,0.4,1.3,0.5c0.4,0.2,1.1,0.6,1.2,0.8c0,0.1-0.1,0.3-0.1,0.3 v1.1c0,0,0.4-0.1,0.8-0.4c0.3-0.3,0.5-0.6,0.5-1.1c0-0.6-0.3-1-1.1-1.5c-0.6-0.4-1.4-0.7-2-0.9c-0.1,0-0.2-0.2-0.1-0.3 c0-0.1,0.1-0.2,0.1-0.2v-1.2c0,0-0.5,0.1-0.7,0.2S54.2,22.8,54,23.1L54,23.1z">
                            </path>
                            <path
                                d="M56.9,26.7c0-0.1,0-0.1-0.1-0.2c-0.2-0.2-0.4-0.3-0.5-0.4c-0.2-0.1-0.6-0.3-0.6-0.3s0,2.8,0.1,4.2 c0,0.3,0.2,0.4,0.5,0.4c0.4,0,0.5-0.2,0.5-0.4C56.8,29.7,56.9,27.8,56.9,26.7L56.9,26.7z">
                            </path>
                            <path
                                d="M57.2,9c0.1,0.1,0.1,0.1,0.2,0.3c0.1,0.4,0,1.1,0.1,1.4c0,0.1,0.1,0.1,0.2,0.1c0.6-0.3,1-0.9,1.6-1.2 c0.3-0.1,1.1,0.3,0.9,0.9c-0.1,0.3-0.2,0.3-0.4,0.5c-0.4,0.4-0.9,0.7-1.3,1.2c-0.3,0.3-0.7,0.6-0.9,0.9c-0.1,0.1-0.2,0.3-0.2,0.3 c-0.9-0.2-1.7-0.1-2.4-0.3v-1c0,0,0.7-0.1,0.8-0.4c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c0.7,0.1,1.1,0.2,1.8,0.5 c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.1-0.1-0.4-0.2c-0.4-0.1-1.5-0.2-1.5-0.2s0.5,0,0.9-0.1c0.2,0,0.4-0.1,0.4-0.1c0-0.1,0-0.3-0.2-0.4 c-0.1-0.1-0.2-0.2-0.3-0.3s-0.3-0.4-0.4-0.5c-0.2-0.1-0.5-0.3-0.5-0.3s-0.1-0.5,0-0.7s0.3-0.3,0.4-0.2C56.1,8.6,56.6,8.9,57.2,9 L57.2,9z">
                            </path>
                            <path
                                d="M55.1,27.7c-0.2,0.2-0.4,0.5-0.5,1.1c0,0.5,0.1,0.6,0.2,0.9c0.1,0.2,0.3,0.2,0.4,0c0.1-0.2,0-0.3,0-0.7 c0-0.3,0.3-0.4,0.3-0.4v-1.2C55.5,27.4,55.3,27.5,55.1,27.7L55.1,27.7z">
                            </path>
                        </g>
                        <path class="fiber-bcbs-logo--logo-blue"
                            d="M55.1,10c-0.1,0.1-0.2,0.2-0.4,0.2c-0.3,0-0.2-0.2-0.4-0.3c-0.1-0.1-0.2,0-0.3-0.2C54.4,9.7,54.9,9.7,55.1,10 L55.1,10L55.1,10z">
                        </path>
                        <g class="fiber-bcbs-logo--logo-black">
                            <path
                                d="M84.8,10.3h0.8c1.6,0,2.4-0.6,2.4-2.5c0-1.6-0.5-2.3-2.1-2.3h-1v4.8H84.8z M84.8,5.1h0.9 c1.2,0,1.7-0.5,1.7-1.9s-0.4-2-1.8-2h-0.8L84.8,5.1L84.8,5.1z M81.3,10.3h1.3V1.2h-1.3V0.7h5.3c1.7,0,3.1,0.4,3.1,2.1 c0,1.6-1.3,2.3-2.7,2.4l0,0c1.7,0,3.3,0.9,3.3,2.8c0,1.6-1.1,2.7-3.5,2.7h-5.6L81.3,10.3L81.3,10.3z">
                            </path>
                            <path d="M90.8,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L90.8,10.3L90.8,10.3z">
                            </path>
                            <path
                                d="M102.7,10.3h1v0.4c-1.4,0-2.2,0-3,0.1V9.7l0,0c-0.5,0.8-1.4,1.1-2.2,1.1c-1.4,0-2.4-0.5-2.4-2.3V4.3h-1V3.9H96 c0.7,0,1.4,0,2.2-0.1V9c0,1.1,0.5,1.2,1.1,1.2c0.9,0,1.6-0.7,1.6-2.2V4.3h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5H102.7z">
                            </path>
                            <path
                                d="M108.9,6.5V5.9c0-0.8-0.2-1.8-1.2-1.8c-1.3,0-1.3,1.5-1.4,2.4H108.9L108.9,6.5z M106.3,7v0.6 c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6 c1.8,0,3.2,1.2,3.2,3.2H106.3L106.3,7z">
                            </path>
                            <path
                                d="M120.9,4.2h-0.4C120.4,2.5,119,1,117.3,1c-2.7,0-3.1,2.8-3.1,4.7c0,1.8,0.2,4.8,3,4.8c1.7,0,3.1-1.5,3.3-3.2 h0.4v2.8c-1.1,0.4-2.6,0.8-3.8,0.8c-3.2,0-5.3-2.2-5.3-5.2s2-5.2,5.3-5.2c1.2,0,2.4,0.3,3.7,0.8L120.9,4.2L120.9,4.2z">
                            </path>
                            <path
                                d="M121.5,10.3h1v-6h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v1.1l0,0c0.3-0.7,1-1.2,1.8-1.2c0.9,0,1.3,0.6,1.3,1.4 c0,0.6-0.3,0.9-0.9,0.9c-0.5,0-0.9-0.3-0.9-0.7c0-0.4,0-0.4,0-0.7c0-0.2-0.1-0.3-0.3-0.3c-0.8,0-1.1,1.9-1.1,3v3h1v0.4h-4 L121.5,10.3L121.5,10.3z">
                            </path>
                            <path
                                d="M131.5,4.1c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2S133,9,133,7.3C132.9,5.7,132.9,4.1,131.5,4.1 L131.5,4.1z M131.5,10.9c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6C135.1,9.4,133.6,10.9,131.5,10.9 L131.5,10.9z">
                            </path>
                            <path
                                d="M135.8,8.2h0.4c0.1,1.3,0.9,2.3,2.1,2.3c0.7,0,1.4-0.3,1.4-1c0-1.6-3.8-0.9-3.8-3.6c0-1.4,1.1-2.2,2.5-2.2 c1.1,0,1.6,0.2,2.2,0.4v1.8h-0.4c-0.1-1-0.9-1.8-1.8-1.8c-0.6,0-1,0.3-1,0.9c0,1.4,3.8,0.8,3.8,3.5c0,1.6-1.4,2.4-2.8,2.4 c-1.1,0-1.9-0.3-2.5-0.5L135.8,8.2L135.8,8.2z">
                            </path>
                            <path
                                d="M141.8,8.2h0.4c0.1,1.3,0.9,2.3,2.1,2.3c0.7,0,1.4-0.3,1.4-1c0-1.6-3.8-0.9-3.8-3.6c0-1.4,1.1-2.2,2.5-2.2 c1.1,0,1.6,0.2,2.2,0.4v1.8h-0.4c-0.1-1-0.9-1.8-1.8-1.8c-0.6,0-1,0.3-1,0.9c0,1.4,3.8,0.8,3.8,3.5c0,1.6-1.4,2.4-2.8,2.4 c-1.1,0-1.9-0.3-2.5-0.5L141.8,8.2L141.8,8.2z">
                            </path>
                            <path
                                d="M154.6,10.3h0.8c1.6,0,2.4-0.6,2.4-2.5c0-1.6-0.5-2.3-2.1-2.3h-1L154.6,10.3L154.6,10.3z M154.6,5.1h0.9 c1.2,0,1.7-0.5,1.7-1.9s-0.4-2-1.8-2h-0.8L154.6,5.1L154.6,5.1z M151,10.3h1.3V1.2H151V0.7h5.3c1.8,0,3.1,0.4,3.1,2.1 c0,1.6-1.3,2.3-2.7,2.4l0,0c1.7,0,3.3,0.9,3.3,2.8c0,1.6-1.1,2.7-3.5,2.7h-5.6L151,10.3L151,10.3z">
                            </path>
                            <path
                                d="M160.6,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L160.6,10.3L160.6,10.3z">
                            </path>
                            <path
                                d="M172.4,10.3h1v0.4c-1.4,0-2.2,0-3,0.1V9.7l0,0c-0.5,0.8-1.4,1.1-2.2,1.1c-1.4,0-2.4-0.5-2.4-2.3V4.3h-1V3.9 h0.9c0.7,0,1.4,0,2.2-0.1V9c0,1.1,0.5,1.2,1.1,1.2c0.9,0,1.6-0.7,1.6-2.2V4.3h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5H172.4z">
                            </path>
                            <path
                                d="M178.6,6.5V5.9c0-0.8-0.2-1.8-1.1-1.8c-1.3,0-1.3,1.5-1.4,2.4H178.6L178.6,6.5z M176.1,7v0.6 c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6 c1.8,0,3.2,1.2,3.2,3.2H176.1L176.1,7z">
                            </path>
                            <path
                                d="M181.5,7.4h0.5c0.1,1.4,1.2,3,3,3c1.1,0,2.2-0.4,2.2-1.7c0-2.5-5.5-1.7-5.5-5.2c0-2.1,1.8-3,3.3-3 c0.8,0,1.6,0.1,2.8,0.6l0.1,2.4h-0.5c-0.3-1.3-1.3-2.4-2.7-2.4c-0.9,0-1.6,0.6-1.6,1.5c0,2.2,5.4,1.4,5.4,5.3 c0,1.8-1.6,3.1-3.6,3.1c-1.1,0-2.2-0.2-3.3-0.7L181.5,7.4L181.5,7.4z">
                            </path>
                            <path
                                d="M189,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v4.2l0,0c0.5-0.7,1.2-1.1,2.2-1.1c1.4,0,2.4,0.8,2.4,2.3v4.3h1 v0.4h-4v-0.4h1V5.7c0-1.1-0.5-1.4-1.1-1.4c-0.8,0-1.6,0.8-1.6,1.9v4h1v0.4h-4L189,10.3L189,10.3z">
                            </path>
                            <path
                                d="M199.8,0.6c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1S199.2,0.6,199.8,0.6L199.8,0.6z M197.8,10.3h1v-6h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5h1v0.4h-4L197.8,10.3L197.8,10.3z">
                            </path>
                            <path
                                d="M207,6.5V5.9c0-0.8-0.2-1.8-1.1-1.8c-1.3,0-1.3,1.5-1.4,2.4H207L207,6.5z M204.5,7v0.6c0,1.2,0.2,2.8,1.8,2.8 c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2 H204.5L204.5,7z">
                            </path>
                            <path
                                d="M209.5,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L209.5,10.3L209.5,10.3z">
                            </path>
                            <path
                                d="M217.6,4.3c-1.4,0-1.5,1.3-1.5,3c0,2,0.4,3,1.5,3c1.5,0,1.6-1.7,1.6-3C219.2,6.2,219.1,4.3,217.6,4.3 L217.6,4.3z M219.1,9.9L219.1,9.9c-0.5,0.8-1.3,1-2.1,1c-2.1,0-3-1.7-3-3.6s1.1-3.6,3.1-3.6c0.9,0,1.6,0.3,1.9,1l0,0V1.2h-1V0.7 h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4c-1.4,0-2.2,0-3,0.1L219.1,9.9L219.1,9.9z">
                            </path>
                            <path
                                d="M85.2,20.9c-1.5,0-1.5,1.6-1.5,3.2s0,3.2,1.5,3.2s1.5-1.5,1.5-3.2C86.6,22.5,86.6,20.9,85.2,20.9L85.2,20.9z M85.2,27.7c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6S87.3,27.7,85.2,27.7L85.2,27.7z">
                            </path>
                            <path
                                d="M89.1,27.1h1v-6h-1v-0.4h1v-0.9c0-1.5,0.8-2.5,2.8-2.5c0.6,0,2.3,0.2,2.3,1.5c0,0.7-0.3,1-0.9,1 c-0.7,0-0.9-0.4-0.9-0.9c0-0.6,0-1.2-0.6-1.2s-0.7,0.5-0.7,1.1v1.8h1.2v0.5h-1.2v6h1.2v0.4h-4.2L89.1,27.1L89.1,27.1z">
                            </path>
                            <polygon
                                points="97.2,27.1 98.5,27.1 98.5,18 97.2,18 97.2,17.5 102.1,17.5 102.1,18 100.7,18 100.7,27.1 102.1,27.1  102.1,27.5 97.2,27.5 	">
                            </polygon>
                            <path
                                d="M102.5,27.1h1v-9.2h-1v-0.4h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L102.5,27.1L102.5,27.1z">
                            </path>
                            <path
                                d="M106.8,27.1h1v-9.2h-1v-0.4h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L106.8,27.1L106.8,27.1z">
                            </path>
                            <path
                                d="M113.1,17.4c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1S112.5,17.4,113.1,17.4L113.1,17.4z M111.1,27.1h1v-6h-1v-0.4h0.9c0.7,0,1.4,0,2.2-0.1v6.5h1v0.4h-4L111.1,27.1L111.1,27.1z">
                            </path>
                            <path
                                d="M115.4,27.1h1v-6h-1v-0.4h0.9c0.7,0,1.4,0,2.2-0.1v1l0,0c0.5-0.7,1.2-1.1,2.2-1.1c1.4,0,2.4,0.8,2.4,2.3v4.3h1 v0.4h-4v-0.4h1v-4.6c0-1.1-0.5-1.4-1.1-1.4c-0.8,0-1.6,0.8-1.6,1.9v4h1v0.4h-4V27.1L115.4,27.1z">
                            </path>
                            <path
                                d="M128.4,20.9c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2s1.5-1.5,1.5-3.2C129.8,22.5,129.8,20.9,128.4,20.9 L128.4,20.9z M128.4,27.7c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c2.1,0,3.6,1.5,3.6,3.6S130.5,27.7,128.4,27.7L128.4,27.7z">
                            </path>
                            <path
                                d="M134.5,17.4c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1S133.9,17.4,134.5,17.4L134.5,17.4z M132.5,27.1h1v-6h-1v-0.4h0.9c0.7,0,1.4,0,2.2-0.1v6.5h1v0.4h-4L132.5,27.1L132.5,27.1z">
                            </path>
                            <path
                                d="M136.9,25h0.4c0.1,1.3,0.9,2.3,2.1,2.3c0.7,0,1.4-0.3,1.4-1c0-1.6-3.8-0.9-3.8-3.6c0-1.4,1.1-2.2,2.5-2.2 c1.1,0,1.6,0.2,2.2,0.4v1.8h-0.4c-0.1-1-0.9-1.8-1.8-1.8c-0.6,0-1,0.3-1,0.9c0,1.4,3.8,0.8,3.8,3.5c0,1.6-1.4,2.4-2.8,2.4 c-1.1,0-1.9-0.3-2.5-0.5L136.9,25L136.9,25z">
                            </path>
                        </g>
                    </svg>
                    <svg *ngIf="imagePath==='mt'"xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 222.1 36.9" aria-hidden="true">
                        <title>Logo for Blue Cross
                        Blue Shield of Montana </title>
                        <desc>Blue Cross
                        Blue Shield of Montana</desc>
                        <g class="fiber-bcbs-logo--logo-blue">
                          <path
                            d="M29.7,34.8c0.3,0,0.7,0,0.7-0.4c0-0.3-0.3-0.4-0.5-0.4h-0.6v0.7L29.7,34.8L29.7,34.8z M30.6,35.9h-0.3L29.7,35
                      h-0.5v0.9H29v-2h0.9c0.5,0,0.7,0.2,0.7,0.5c0,0.4-0.3,0.6-0.6,0.6L30.6,35.9L30.6,35.9z M31.3,34.9c0-0.9-0.6-1.6-1.5-1.6
                      s-1.5,0.7-1.5,1.6s0.6,1.6,1.5,1.6C30.6,36.5,31.3,35.8,31.3,34.9L31.3,34.9z M27.9,34.9c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8
                      s-0.8,1.8-1.8,1.8S27.9,35.9,27.9,34.9L27.9,34.9z"
                          />
                          <path
                            d="M64,34.8c0.3,0,0.7,0,0.7-0.4c0-0.3-0.3-0.4-0.5-0.4h-0.5v0.7L64,34.8L64,34.8z M65,35.9h-0.3L64.1,35h-0.4
                      v0.9h-0.3v-2h0.9c0.5,0,0.7,0.2,0.7,0.5c0,0.4-0.3,0.6-0.6,0.6L65,35.9L65,35.9z M65.6,34.9c0-0.9-0.6-1.6-1.5-1.6
                      c-0.9,0-1.5,0.7-1.5,1.6s0.7,1.6,1.5,1.6C65,36.5,65.6,35.8,65.6,34.9L65.6,34.9z M62.3,34.9c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8
                      s-0.8,1.8-1.8,1.8C63.1,36.7,62.3,35.9,62.3,34.9L62.3,34.9z"
                          />
                          <polygon
                            points="26.1,36.5 26.1,26.5 36.2,26.5 36.2,10.4 26.1,10.4 26.1,0.4 10.1,0.4 10.1,10.4 0,10.4 0,26.5 
                      10.1,26.5 10.1,36.5 	"
                          />
                          <path
                            d="M56,36.9c6.9-3.8,11.1-9.9,13.3-16.6c2.2-7,1.9-15.2-0.7-20.3c-4,2-8,3-12.6,3s-8.6-1-12.6-3
                      c-2.6,5.1-3,13.4-0.7,20.3C44.8,27,49.1,33.1,56,36.9L56,36.9L56,36.9z"
                          />
                        </g>
                        <g class="fiber-bcbs-logo--logo-white">
                          <path
                            d="M8.8,15l6.7,0.6c1.3,0.2,1.4,1.4,1.1,2.1L12,26.3l0,0c-2.3-1.8-3.8-4.6-3.8-7.8C8.2,17.2,8.4,16.1,8.8,15
                      L8.8,15L8.8,15z"
                          />
                          <path
                            d="M27.4,15l-6.7,0.6c-1.3,0.2-1.4,1.4-1.1,2.1l4.6,8.6l0,0c2.3-1.8,3.8-4.6,3.8-7.8C28.1,17.2,27.8,16.1,27.4,15
                      L27.4,15L27.4,15z"
                          />
                          <path
                            d="M18.1,9.8c1.2,0,1.5,1.3,1,2c-0.5,0.6-0.6,0.8-0.5,1.4c0,0.5,0.5,1.1,1.5,1.1h7.1c-1.6-3.4-5-5.7-9-5.7
                      s-7.4,2.3-9,5.7h7.1c1,0,1.5-0.6,1.5-1.1c0-0.6-0.1-0.8-0.5-1.4C16.6,11.1,17,9.8,18.1,9.8L18.1,9.8z"
                          />
                          <path
                            d="M18.1,28.4c2,0,3.9-0.6,5.5-1.6l0,0l-4.9-7.3c-0.3-0.5-0.9-0.5-1.2,0l-4.8,7.3l0,0
                      C14.2,27.8,16.1,28.4,18.1,28.4"
                          />
                          <path
                            d="M56,33.8c5.7-3.5,8.7-8.6,10.7-14.3c1.7-5.1,2.2-11,0.2-15.7c-2.9,1-4.5,1.3-6.1,1.5c-1.4,0.2-2.7,0.4-4.9,0.4
                      l0,0c-2.1,0-3.4-0.2-4.9-0.4c-1.6-0.2-3.2-0.5-6.1-1.5c-1.9,4.6-1.5,10.6,0.3,15.7C47.2,25.2,50.3,30.3,56,33.8L56,33.8L56,33.8z"
                          />
                        </g>
                        <g class="fiber-bcbs-logo--logo-blue">
                          <path
                            d="M9.8,16.4l5,0.5c0.4,0,0.4,0.2,0.3,0.4L11.6,24c-1.3-1.5-2.1-3.4-2.1-5.6C9.6,17.8,9.7,17.1,9.8,16.4L9.8,16.4
                      z"
                          />
                          <path d="M15.8,10.2c-0.5,0.8-0.1,1.9,0.4,2.5c0.1,0.1,0,0.2,0,0.2h-4.5C12.7,11.7,14.1,10.7,15.8,10.2L15.8,10.2z" />
                          <path
                            d="M26.4,16.4l-5,0.5c-0.4,0-0.4,0.2-0.3,0.4l3.5,6.7c1.3-1.5,2.1-3.4,2.1-5.6C26.7,17.8,26.6,17.1,26.4,16.4
                      L26.4,16.4z"
                          />
                          <path d="M20.5,10.2c0.5,0.8,0.1,1.9-0.4,2.5c-0.1,0.1,0,0.2,0,0.2h4.5C23.6,11.7,22.1,10.7,20.5,10.2L20.5,10.2z" />
                          <path
                            d="M56,32.4c3.9-2.4,8.2-7.9,10-15.2c0.9-3.8,1.2-8.2,0-11.6c-1.7,0.5-3.3,0.8-5,1.1c-1.7,0.2-3.3,0.4-4.9,0.4
                      l0,0c-1.6,0-3.3-0.2-4.9-0.4c-1.7-0.2-3.3-0.6-5-1.1c-1.2,3.4-1,7.8,0,11.6C47.8,24.5,52.1,30,56,32.4L56,32.4L56,32.4z"
                          />
                          <path
                            d="M18,21.2c0.1-0.1,0.2-0.1,0.3,0l3.3,5.1c-1.1,0.5-2.2,0.7-3.5,0.7c-1.2,0-2.4-0.3-3.5-0.7L18,21.2
                      c0.1-0.1,0.2-0.1,0.3,0"
                          />
                        </g>
                        <g class="fiber-bcbs-logo--logo-white">
                          <path
                            d="M52,9.6c-1.3,0.7-1.8,2.3-1.2,3.6c0.5,1.1,1.5,1.9,2.7,2.2c0.6,0.2,1.2,0.2,1.8,0.2c0.7,0,1.4,0.1,2.1,0.2
                      c0.2,0,0.3,0.1,0.4,0.3c0.2,0.4,0,0.7-0.4,0.9c0,0.6-0.1,1.9-0.1,1.9s3-0.3,2.5-3c-0.1-0.7-0.6-1.3-1.2-1.6
                      c-1.5-0.8-3.2-0.4-4.7-0.9c-0.5-0.2-1.3-0.4-1.4-1c-0.1-0.2,0-0.7,0.2-0.8c0.4-0.1,0.8,0.2,1.4,0.3c0.4,0.1,0.7,0.1,1,0
                      c0.1,0,0.3-0.1,0.3-0.2c0-0.1-0.2-0.1-0.2-0.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.3-0.1-0.6-0.3-0.9-0.4c0.3-0.1,0.8-0.1,1.1,0
                      c0.2,0,1,0,1.2,0c0,0,0.2-0.1,0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.3-0.4-0.6-0.7-0.7C54.2,9.2,53,9.1,52,9.6L52,9.6z"
                          />
                          <path
                            d="M57.1,19.4v-3.2c0,0-0.4-0.1-1-0.1c-0.7-0.1-1.1,0-1.1,0v0.3l-0.4,0.2c0,0-0.1,0.2-0.1,0.3
                      c0.3,0.8,0.7,1.5,0.8,2.4C55.9,19.3,56.4,19.3,57.1,19.4L57.1,19.4z"
                          />
                          <path
                            d="M53.3,18.9c-0.1,1.2,0.7,1.9,1.6,2.1c0.7,0.2,1.4,0.2,2.1,0.3c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.1,0.2,0.2
                      s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.3,0.2v1.5c0,0,0.6,0,1.1-0.3s0.7-0.6,0.9-1.1c0.1-0.5,0-1-0.3-1.5c-0.2-0.3-0.7-0.6-1.1-0.7
                      c-0.9-0.3-1.8-0.3-2.8-0.4c-0.1,0-0.3-0.1-0.3-0.3s0.2-0.3,0.2-0.3s-0.1-0.4-0.2-0.7c-0.1-0.3-0.3-0.6-0.4-0.8
                      C53.8,17.7,53.4,18.2,53.3,18.9L53.3,18.9z"
                          />
                          <path d="M57,21.7v2.7l-0.7-0.3l-0.6-0.3v-2.2C56.2,21.6,56.5,21.6,57,21.7L57,21.7z" />
                          <path
                            d="M54,23.1c-0.4,0.9,0.1,1.7,0.8,2.1c0.4,0.2,0.9,0.4,1.3,0.5c0.4,0.2,1.1,0.6,1.2,0.8c0,0.1-0.1,0.3-0.1,0.3
                      v1.1c0,0,0.4-0.1,0.8-0.4c0.3-0.3,0.5-0.6,0.5-1.1c0-0.6-0.3-1-1.1-1.5c-0.6-0.4-1.4-0.7-2-0.9c-0.1,0-0.2-0.2-0.1-0.3
                      c0-0.1,0.1-0.2,0.1-0.2v-1.2c0,0-0.5,0.1-0.7,0.2S54.2,22.8,54,23.1L54,23.1z"
                          />
                          <path
                            d="M56.9,26.7c0-0.1,0-0.1-0.1-0.2c-0.2-0.2-0.4-0.3-0.5-0.4c-0.2-0.1-0.6-0.3-0.6-0.3s0,2.8,0.1,4.2
                      c0,0.3,0.2,0.4,0.5,0.4c0.4,0,0.5-0.2,0.5-0.4C56.8,29.7,56.9,27.8,56.9,26.7L56.9,26.7z"
                          />
                          <path
                            d="M57.2,9c0.1,0.1,0.1,0.1,0.2,0.3c0.1,0.4,0,1.1,0.1,1.4c0,0.1,0.1,0.1,0.2,0.1c0.6-0.3,1-0.9,1.6-1.2
                      c0.3-0.1,1.1,0.3,0.9,0.9c-0.1,0.3-0.2,0.3-0.4,0.5c-0.4,0.4-0.9,0.7-1.3,1.2c-0.3,0.3-0.7,0.6-0.9,0.9c-0.1,0.1-0.2,0.3-0.2,0.3
                      c-0.9-0.2-1.7-0.1-2.4-0.3v-1c0,0,0.7-0.1,0.8-0.4c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c0.7,0.1,1.1,0.2,1.8,0.5
                      c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.1-0.1-0.4-0.2c-0.4-0.1-1.5-0.2-1.5-0.2s0.5,0,0.9-0.1c0.2,0,0.4-0.1,0.4-0.1c0-0.1,0-0.3-0.2-0.4
                      c-0.1-0.1-0.2-0.2-0.3-0.3s-0.3-0.4-0.4-0.5c-0.2-0.1-0.5-0.3-0.5-0.3s-0.1-0.5,0-0.7s0.3-0.3,0.4-0.2C56.1,8.6,56.6,8.9,57.2,9
                      L57.2,9z"
                          />
                          <path
                            d="M55.1,27.7c-0.2,0.2-0.4,0.5-0.5,1.1c0,0.5,0.1,0.6,0.2,0.9c0.1,0.2,0.3,0.2,0.4,0c0.1-0.2,0-0.3,0-0.7
                      c0-0.3,0.3-0.4,0.3-0.4v-1.2C55.5,27.4,55.3,27.5,55.1,27.7L55.1,27.7z"
                          />
                        </g>
                        <path
                          class="fiber-bcbs-logo--logo-blue"
                          d="M55.1,10c-0.1,0.1-0.2,0.2-0.4,0.2c-0.3,0-0.2-0.2-0.4-0.3c-0.1-0.1-0.2,0-0.3-0.2C54.4,9.7,54.9,9.7,55.1,10
                      L55.1,10L55.1,10z"
                        />
                        <g class="bcbs-logo--logo-black">
                        <path
                        d="M84.8,10.3h0.8c1.6,0,2.4-0.6,2.4-2.5c0-1.6-0.5-2.3-2.1-2.3h-1v4.8H84.8z M84.8,5.1h0.9
                    c1.2,0,1.7-0.5,1.7-1.9s-0.4-2-1.8-2h-0.8V5.1L84.8,5.1z M81.3,10.3h1.3V1.2h-1.3V0.7h5.3c1.8,0,3.1,0.4,3.1,2.1
                    c0,1.6-1.3,2.3-2.7,2.4l0,0c1.7,0,3.3,0.9,3.3,2.8c0,1.6-1.1,2.7-3.5,2.7h-5.6L81.3,10.3L81.3,10.3z"
                      />
                      <path d="M90.8,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L90.8,10.3L90.8,10.3z" />
                      <path
                        d="M102.7,10.3h1v0.4c-1.4,0-2.2,0-3,0.1V9.7l0,0c-0.5,0.8-1.4,1.1-2.2,1.1c-1.4,0-2.4-0.5-2.4-2.3V4.3h-1V3.9H96
                    c0.7,0,1.4,0,2.2-0.1V9c0,1.1,0.5,1.2,1.1,1.2c0.9,0,1.6-0.7,1.6-2.2V4.3h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5H102.7z"
                      />
                      <path
                        d="M108.8,6.5V5.9c0-0.8-0.2-1.8-1.1-1.8c-1.3,0-1.3,1.5-1.4,2.4H108.8L108.8,6.5z M106.3,7v0.6
                    c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6
                    c1.8,0,3.2,1.2,3.2,3.2H106.3L106.3,7z"
                      />
                      <path
                        d="M120.9,4.2h-0.4C120.4,2.5,119,1,117.3,1c-2.7,0-3.1,2.8-3.1,4.7c0,1.8,0.2,4.8,3,4.8c1.7,0,3.1-1.5,3.3-3.2
                    h0.4v2.8c-1.1,0.4-2.6,0.8-3.8,0.8c-3.2,0-5.3-2.2-5.3-5.2s2-5.2,5.3-5.2c1.2,0,2.4,0.3,3.7,0.8L120.9,4.2L120.9,4.2z"
                      />
                      <path
                        d="M121.5,10.3h1v-6h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v1.1l0,0c0.3-0.7,1-1.2,1.8-1.2c0.9,0,1.3,0.6,1.3,1.4
                    c0,0.6-0.3,0.9-0.9,0.9c-0.5,0-0.9-0.3-0.9-0.7c0-0.4,0-0.4,0-0.7c0-0.2-0.1-0.3-0.3-0.3c-0.8,0-1.1,1.9-1.1,3v3h1v0.4h-4V10.3
                    L121.5,10.3z"
                      />
                      <path
                        d="M131.4,4.1c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2s1.5-1.5,1.5-3.2C132.9,5.7,132.9,4.1,131.4,4.1
                    L131.4,4.1z M131.4,10.9c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6C135.1,9.4,133.6,10.9,131.4,10.9
                    L131.4,10.9z"
                      />
                      <path
                        d="M135.8,8.2h0.4c0.1,1.3,0.9,2.3,2.1,2.3c0.7,0,1.4-0.3,1.4-1c0-1.6-3.8-0.9-3.8-3.6c0-1.4,1.1-2.2,2.5-2.2
                    c1.1,0,1.6,0.2,2.2,0.4v1.8h-0.4c-0.1-1-0.9-1.8-1.8-1.8c-0.6,0-1,0.3-1,0.9c0,1.4,3.8,0.8,3.8,3.5c0,1.6-1.4,2.4-2.8,2.4
                    c-1.1,0-1.9-0.3-2.5-0.5L135.8,8.2L135.8,8.2z"
                      />
                      <path
                        d="M141.8,8.2h0.4c0.1,1.3,0.9,2.3,2.1,2.3c0.7,0,1.4-0.3,1.4-1c0-1.6-3.8-0.9-3.8-3.6c0-1.4,1.1-2.2,2.5-2.2
                    c1.1,0,1.6,0.2,2.2,0.4v1.8h-0.4c-0.1-1-0.9-1.8-1.8-1.8c-0.6,0-1,0.3-1,0.9c0,1.4,3.8,0.8,3.8,3.5c0,1.6-1.4,2.4-2.8,2.4
                    c-1.1,0-1.9-0.3-2.5-0.5L141.8,8.2L141.8,8.2z"
                      />
                      <path
                        d="M154.6,10.3h0.8c1.6,0,2.4-0.6,2.4-2.5c0-1.6-0.5-2.3-2.1-2.3h-1L154.6,10.3L154.6,10.3z M154.6,5.1h0.9
                    c1.2,0,1.7-0.5,1.7-1.9s-0.4-2-1.8-2h-0.8L154.6,5.1L154.6,5.1z M151,10.3h1.3V1.2H151V0.7h5.3c1.8,0,3.1,0.4,3.1,2.1
                    c0,1.6-1.3,2.3-2.7,2.4l0,0c1.7,0,3.3,0.9,3.3,2.8c0,1.6-1.1,2.7-3.5,2.7h-5.6L151,10.3L151,10.3z"
                      />
                      <path d="M160.5,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L160.5,10.3L160.5,10.3z" />
                      <path
                        d="M172.4,10.3h1v0.4c-1.4,0-2.2,0-3,0.1V9.7l0,0c-0.5,0.8-1.4,1.1-2.2,1.1c-1.4,0-2.4-0.5-2.4-2.3V4.3h-1V3.9
                    h0.9c0.7,0,1.4,0,2.2-0.1V9c0,1.1,0.5,1.2,1.1,1.2c0.9,0,1.6-0.7,1.6-2.2V4.3h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5H172.4z"
                      />
                      <path
                        d="M178.6,6.5V5.9c0-0.8-0.2-1.8-1.1-1.8c-1.3,0-1.3,1.5-1.4,2.4H178.6L178.6,6.5z M176.1,7v0.6
                    c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6
                    c1.8,0,3.2,1.2,3.2,3.2H176.1L176.1,7z"
                      />
                      <path
                        d="M181.4,7.4h0.5c0.1,1.4,1.2,3,3,3c1.1,0,2.2-0.4,2.2-1.7c0-2.5-5.5-1.7-5.5-5.2c0-2.1,1.8-3,3.3-3
                    c0.8,0,1.6,0.1,2.8,0.6l0.1,2.4h-0.5C187,2.1,186,1,184.6,1c-0.9,0-1.6,0.6-1.6,1.5c0,2.2,5.4,1.4,5.4,5.3c0,1.8-1.6,3.1-3.6,3.1
                    c-1.1,0-2.2-0.2-3.3-0.7L181.4,7.4L181.4,7.4z"
                      />
                      <path
                        d="M188.9,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v4.2l0,0c0.5-0.7,1.2-1.1,2.2-1.1c1.4,0,2.4,0.8,2.4,2.3v4.3
                    h1v0.4h-4v-0.4h1V5.7c0-1.1-0.5-1.4-1.1-1.4c-0.8,0-1.6,0.8-1.6,1.9v4h1v0.4h-4L188.9,10.3L188.9,10.3z"
                      />
                      <path
                        d="M199.8,0.6c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1S199.2,0.6,199.8,0.6L199.8,0.6z
                    M197.8,10.3h1v-6h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5h1v0.4h-4L197.8,10.3L197.8,10.3z"
                      />
                      <path
                        d="M207,6.5V5.9c0-0.8-0.2-1.8-1.2-1.8c-1.3,0-1.3,1.5-1.4,2.4H207L207,6.5z M204.5,7v0.6c0,1.2,0.2,2.8,1.8,2.8
                    c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2
                    H204.5L204.5,7z"
                      />
                      <path d="M209.5,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L209.5,10.3L209.5,10.3z" />
                      <path
                        d="M217.6,4.3c-1.4,0-1.5,1.3-1.5,3c0,2,0.4,3,1.5,3c1.5,0,1.6-1.7,1.6-3C219.2,6.2,219.1,4.3,217.6,4.3
                    L217.6,4.3z M219.1,9.9L219.1,9.9c-0.5,0.8-1.3,1-2.1,1c-2.1,0-3-1.7-3-3.6s1.1-3.6,3.1-3.6c0.9,0,1.6,0.3,1.9,1l0,0V1.2h-1V0.7
                    h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4c-1.4,0-2.2,0-3,0.1L219.1,9.9L219.1,9.9z"
                      />
                      <path
                        d="M85.1,20.9c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2s1.5-1.5,1.5-3.2C86.6,22.5,86.6,20.9,85.1,20.9
                    L85.1,20.9z M85.1,27.7c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.7,1.5,3.7,3.6S87.3,27.7,85.1,27.7L85.1,27.7z"
                      />
                      <path
                        d="M89.1,27.1h1v-6h-1v-0.4h1v-0.9c0-1.5,0.8-2.5,2.8-2.5c0.6,0,2.3,0.2,2.3,1.5c0,0.7-0.3,1-0.9,1
                    c-0.7,0-0.9-0.4-0.9-0.9c0-0.6,0-1.2-0.6-1.2s-0.7,0.5-0.7,1.1v1.8h1.2v0.5h-1.2v6h1.2v0.4h-4.2L89.1,27.1L89.1,27.1z"
                      />
                      <polygon
                        points="98.7,27.1 100.1,27.1 100.1,17.9 98.7,17.9 98.7,17.5 102.5,17.5 105.2,24.7 108.2,17.5 111.9,17.5 
                    111.9,17.9 110.5,17.9 110.5,27.1 111.9,27.1 111.9,27.5 106.8,27.5 106.8,27.1 108.3,27.1 108.3,18.7 108.3,18.7 104.6,27.7 
                    104.1,27.7 100.5,18.7 100.5,18.7 100.5,27.1 102.1,27.1 102.1,27.5 98.7,27.5 	"
                      />
                      <path
                        d="M115.7,20.9c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2s1.5-1.5,1.5-3.2C117.1,22.4,117.1,20.9,115.7,20.9
                    L115.7,20.9z M115.7,27.6c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6S117.8,27.6,115.7,27.6L115.7,27.6z"
                      />
                      <path
                        d="M119.6,27.1h1v-6h-1v-0.4h0.9c0.7,0,1.4,0,2.1-0.1v1l0,0c0.5-0.7,1.2-1.1,2.2-1.1c1.4,0,2.4,0.8,2.4,2.3v4.3h1
                    v0.4h-4v-0.4h1v-4.6c0-1.1-0.5-1.4-1.1-1.4c-0.8,0-1.5,0.8-1.5,1.9v4h1v0.4h-4L119.6,27.1L119.6,27.1z"
                      />
                      <path
                        d="M129.5,18.7h2v1.9h1.4v0.5h-1.4V26c0,0.5,0,1.1,0.7,1.1c0.5,0,0.8-0.3,1-0.4l0.3,0.3c-0.6,0.6-1.3,0.8-2.1,0.8
                    c-0.9,0-1.8-0.1-1.8-1.8v-4.9h-1v-0.4h1L129.5,18.7L129.5,18.7z"
                      />
                      <path
                        d="M138.3,23.3l-1.2,0.4c-0.8,0.3-1.2,0.7-1.2,1.9c0,0.7,0.3,1.3,1,1.3c1.3,0,1.3-1.7,1.3-2.5L138.3,23.3
                    L138.3,23.3z M140.3,25.9c0,0.9,0.1,1.2,1,1.2v0.4c-0.5,0-1,0.1-1.6,0.1c-0.8,0-1.3-0.2-1.4-1l0,0c-0.3,0.9-1.4,1.1-2.3,1.1
                    c-1.4,0-2.1-0.9-2.1-2.2c0-1.7,1.3-1.8,3-2.1c0.3-0.1,1-0.3,1.4-0.4v-0.9c0-0.6-0.2-1.1-1.2-1.1c-1.9,0-0.3,1.9-1.9,1.9
                    c-0.4,0-0.8-0.2-0.8-0.8c0-1.2,1.7-1.5,2.7-1.5c1.9,0,3.3,0.2,3.3,2.1L140.3,25.9L140.3,25.9z"
                      />
                      <path
                        d="M141.8,27.1h1v-6h-1v-0.4h0.9c0.7,0,1.4,0,2.1-0.1v1l0,0c0.5-0.7,1.2-1.1,2.2-1.1c1.4,0,2.4,0.8,2.4,2.3v4.3h1
                    v0.4h-4v-0.4h1v-4.6c0-1.1-0.5-1.4-1.1-1.4c-0.8,0-1.5,0.8-1.5,1.9v4h1v0.4h-4L141.8,27.1L141.8,27.1z"
                      />
                      <path
                        d="M155.7,23.3l-1.2,0.4c-0.8,0.3-1.2,0.7-1.2,1.9c0,0.7,0.3,1.3,1,1.3c1.3,0,1.3-1.7,1.3-2.5L155.7,23.3
                    L155.7,23.3z M157.7,25.9c0,0.9,0.1,1.2,1,1.2v0.4c-0.5,0-1,0.1-1.6,0.1c-0.8,0-1.3-0.2-1.4-1l0,0c-0.3,0.9-1.4,1.1-2.3,1.1
                    c-1.4,0-2.1-0.9-2.1-2.2c0-1.7,1.3-1.8,3-2.1c0.3-0.1,1-0.3,1.4-0.4v-0.9c0-0.6-0.2-1.1-1.2-1.1c-1.9,0-0.3,1.9-1.9,1.9
                    c-0.4,0-0.8-0.2-0.8-0.8c0-1.2,1.7-1.5,2.7-1.5c1.9,0,3.3,0.2,3.3,2.1L157.7,25.9L157.7,25.9z"
                      />
                        </g>
                      </svg>
                      <svg *ngIf="imagePath==='nm'"xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 222.1 36.9" aria-hidden="true">
                        <title>Logo for Blue Cross
                        Blue Shield of New Mexico </title>
                        <desc>Blue Cross
                        Blue Shield of New Mexico</desc>
                        <g class="fiber-bcbs-logo--logo-blue">
                          <path
                            d="M29.7,34.8c0.3,0,0.7,0,0.7-0.4c0-0.3-0.3-0.4-0.5-0.4h-0.6v0.7L29.7,34.8L29.7,34.8z M30.6,35.9h-0.3L29.7,35
                      h-0.5v0.9H29v-2h0.9c0.5,0,0.7,0.2,0.7,0.5c0,0.4-0.3,0.6-0.6,0.6L30.6,35.9L30.6,35.9z M31.3,34.9c0-0.9-0.6-1.6-1.5-1.6
                      s-1.5,0.7-1.5,1.6s0.6,1.6,1.5,1.6C30.6,36.5,31.3,35.8,31.3,34.9L31.3,34.9z M27.9,34.9c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8
                      s-0.8,1.8-1.8,1.8S27.9,35.9,27.9,34.9L27.9,34.9z"
                          />
                          <path
                            d="M64,34.8c0.3,0,0.7,0,0.7-0.4c0-0.3-0.3-0.4-0.5-0.4h-0.5v0.7L64,34.8L64,34.8z M65,35.9h-0.3L64.1,35h-0.4
                      v0.9h-0.3v-2h0.9c0.5,0,0.7,0.2,0.7,0.5c0,0.4-0.3,0.6-0.6,0.6L65,35.9L65,35.9z M65.6,34.9c0-0.9-0.6-1.6-1.5-1.6
                      c-0.9,0-1.5,0.7-1.5,1.6s0.7,1.6,1.5,1.6C65,36.5,65.6,35.8,65.6,34.9L65.6,34.9z M62.3,34.9c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8
                      s-0.8,1.8-1.8,1.8C63.1,36.7,62.3,35.9,62.3,34.9L62.3,34.9z"
                          />
                          <polygon
                            points="26.1,36.5 26.1,26.5 36.2,26.5 36.2,10.4 26.1,10.4 26.1,0.4 10.1,0.4 10.1,10.4 0,10.4 0,26.5 
                      10.1,26.5 10.1,36.5 	"
                          />
                          <path
                            d="M56,36.9c6.9-3.8,11.1-9.9,13.3-16.6c2.2-7,1.9-15.2-0.7-20.3c-4,2-8,3-12.6,3s-8.6-1-12.6-3
                      c-2.6,5.1-3,13.4-0.7,20.3C44.8,27,49.1,33.1,56,36.9L56,36.9L56,36.9z"
                          />
                        </g>
                        <g class="fiber-bcbs-logo--logo-white">
                          <path
                            d="M8.8,15l6.7,0.6c1.3,0.2,1.4,1.4,1.1,2.1L12,26.3l0,0c-2.3-1.8-3.8-4.6-3.8-7.8C8.2,17.2,8.4,16.1,8.8,15
                      L8.8,15L8.8,15z"
                          />
                          <path
                            d="M27.4,15l-6.7,0.6c-1.3,0.2-1.4,1.4-1.1,2.1l4.6,8.6l0,0c2.3-1.8,3.8-4.6,3.8-7.8C28.1,17.2,27.8,16.1,27.4,15
                      L27.4,15L27.4,15z"
                          />
                          <path
                            d="M18.1,9.8c1.2,0,1.5,1.3,1,2c-0.5,0.6-0.6,0.8-0.5,1.4c0,0.5,0.5,1.1,1.5,1.1h7.1c-1.6-3.4-5-5.7-9-5.7
                      s-7.4,2.3-9,5.7h7.1c1,0,1.5-0.6,1.5-1.1c0-0.6-0.1-0.8-0.5-1.4C16.6,11.1,17,9.8,18.1,9.8L18.1,9.8z"
                          />
                          <path
                            d="M18.1,28.4c2,0,3.9-0.6,5.5-1.6l0,0l-4.9-7.3c-0.3-0.5-0.9-0.5-1.2,0l-4.8,7.3l0,0
                      C14.2,27.8,16.1,28.4,18.1,28.4"
                          />
                          <path
                            d="M56,33.8c5.7-3.5,8.7-8.6,10.7-14.3c1.7-5.1,2.2-11,0.2-15.7c-2.9,1-4.5,1.3-6.1,1.5c-1.4,0.2-2.7,0.4-4.9,0.4
                      l0,0c-2.1,0-3.4-0.2-4.9-0.4c-1.6-0.2-3.2-0.5-6.1-1.5c-1.9,4.6-1.5,10.6,0.3,15.7C47.2,25.2,50.3,30.3,56,33.8L56,33.8L56,33.8z"
                          />
                        </g>
                        <g class="fiber-bcbs-logo--logo-blue">
                          <path
                            d="M9.8,16.4l5,0.5c0.4,0,0.4,0.2,0.3,0.4L11.6,24c-1.3-1.5-2.1-3.4-2.1-5.6C9.6,17.8,9.7,17.1,9.8,16.4L9.8,16.4
                      z"
                          />
                          <path d="M15.8,10.2c-0.5,0.8-0.1,1.9,0.4,2.5c0.1,0.1,0,0.2,0,0.2h-4.5C12.7,11.7,14.1,10.7,15.8,10.2L15.8,10.2z" />
                          <path
                            d="M26.4,16.4l-5,0.5c-0.4,0-0.4,0.2-0.3,0.4l3.5,6.7c1.3-1.5,2.1-3.4,2.1-5.6C26.7,17.8,26.6,17.1,26.4,16.4
                      L26.4,16.4z"
                          />
                          <path d="M20.5,10.2c0.5,0.8,0.1,1.9-0.4,2.5c-0.1,0.1,0,0.2,0,0.2h4.5C23.6,11.7,22.1,10.7,20.5,10.2L20.5,10.2z" />
                          <path
                            d="M56,32.4c3.9-2.4,8.2-7.9,10-15.2c0.9-3.8,1.2-8.2,0-11.6c-1.7,0.5-3.3,0.8-5,1.1c-1.7,0.2-3.3,0.4-4.9,0.4
                      l0,0c-1.6,0-3.3-0.2-4.9-0.4c-1.7-0.2-3.3-0.6-5-1.1c-1.2,3.4-1,7.8,0,11.6C47.8,24.5,52.1,30,56,32.4L56,32.4L56,32.4z"
                          />
                          <path
                            d="M18,21.2c0.1-0.1,0.2-0.1,0.3,0l3.3,5.1c-1.1,0.5-2.2,0.7-3.5,0.7c-1.2,0-2.4-0.3-3.5-0.7L18,21.2
                      c0.1-0.1,0.2-0.1,0.3,0"
                          />
                        </g>
                        <g class="fiber-bcbs-logo--logo-white">
                          <path
                            d="M52,9.6c-1.3,0.7-1.8,2.3-1.2,3.6c0.5,1.1,1.5,1.9,2.7,2.2c0.6,0.2,1.2,0.2,1.8,0.2c0.7,0,1.4,0.1,2.1,0.2
                      c0.2,0,0.3,0.1,0.4,0.3c0.2,0.4,0,0.7-0.4,0.9c0,0.6-0.1,1.9-0.1,1.9s3-0.3,2.5-3c-0.1-0.7-0.6-1.3-1.2-1.6
                      c-1.5-0.8-3.2-0.4-4.7-0.9c-0.5-0.2-1.3-0.4-1.4-1c-0.1-0.2,0-0.7,0.2-0.8c0.4-0.1,0.8,0.2,1.4,0.3c0.4,0.1,0.7,0.1,1,0
                      c0.1,0,0.3-0.1,0.3-0.2c0-0.1-0.2-0.1-0.2-0.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.3-0.1-0.6-0.3-0.9-0.4c0.3-0.1,0.8-0.1,1.1,0
                      c0.2,0,1,0,1.2,0c0,0,0.2-0.1,0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.3-0.4-0.6-0.7-0.7C54.2,9.2,53,9.1,52,9.6L52,9.6z"
                          />
                          <path
                            d="M57.1,19.4v-3.2c0,0-0.4-0.1-1-0.1c-0.7-0.1-1.1,0-1.1,0v0.3l-0.4,0.2c0,0-0.1,0.2-0.1,0.3
                      c0.3,0.8,0.7,1.5,0.8,2.4C55.9,19.3,56.4,19.3,57.1,19.4L57.1,19.4z"
                          />
                          <path
                            d="M53.3,18.9c-0.1,1.2,0.7,1.9,1.6,2.1c0.7,0.2,1.4,0.2,2.1,0.3c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.1,0.2,0.2
                      s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.3,0.2v1.5c0,0,0.6,0,1.1-0.3s0.7-0.6,0.9-1.1c0.1-0.5,0-1-0.3-1.5c-0.2-0.3-0.7-0.6-1.1-0.7
                      c-0.9-0.3-1.8-0.3-2.8-0.4c-0.1,0-0.3-0.1-0.3-0.3s0.2-0.3,0.2-0.3s-0.1-0.4-0.2-0.7c-0.1-0.3-0.3-0.6-0.4-0.8
                      C53.8,17.7,53.4,18.2,53.3,18.9L53.3,18.9z"
                          />
                          <path d="M57,21.7v2.7l-0.7-0.3l-0.6-0.3v-2.2C56.2,21.6,56.5,21.6,57,21.7L57,21.7z" />
                          <path
                            d="M54,23.1c-0.4,0.9,0.1,1.7,0.8,2.1c0.4,0.2,0.9,0.4,1.3,0.5c0.4,0.2,1.1,0.6,1.2,0.8c0,0.1-0.1,0.3-0.1,0.3
                      v1.1c0,0,0.4-0.1,0.8-0.4c0.3-0.3,0.5-0.6,0.5-1.1c0-0.6-0.3-1-1.1-1.5c-0.6-0.4-1.4-0.7-2-0.9c-0.1,0-0.2-0.2-0.1-0.3
                      c0-0.1,0.1-0.2,0.1-0.2v-1.2c0,0-0.5,0.1-0.7,0.2S54.2,22.8,54,23.1L54,23.1z"
                          />
                          <path
                            d="M56.9,26.7c0-0.1,0-0.1-0.1-0.2c-0.2-0.2-0.4-0.3-0.5-0.4c-0.2-0.1-0.6-0.3-0.6-0.3s0,2.8,0.1,4.2
                      c0,0.3,0.2,0.4,0.5,0.4c0.4,0,0.5-0.2,0.5-0.4C56.8,29.7,56.9,27.8,56.9,26.7L56.9,26.7z"
                          />
                          <path
                            d="M57.2,9c0.1,0.1,0.1,0.1,0.2,0.3c0.1,0.4,0,1.1,0.1,1.4c0,0.1,0.1,0.1,0.2,0.1c0.6-0.3,1-0.9,1.6-1.2
                      c0.3-0.1,1.1,0.3,0.9,0.9c-0.1,0.3-0.2,0.3-0.4,0.5c-0.4,0.4-0.9,0.7-1.3,1.2c-0.3,0.3-0.7,0.6-0.9,0.9c-0.1,0.1-0.2,0.3-0.2,0.3
                      c-0.9-0.2-1.7-0.1-2.4-0.3v-1c0,0,0.7-0.1,0.8-0.4c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c0.7,0.1,1.1,0.2,1.8,0.5
                      c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.1-0.1-0.4-0.2c-0.4-0.1-1.5-0.2-1.5-0.2s0.5,0,0.9-0.1c0.2,0,0.4-0.1,0.4-0.1c0-0.1,0-0.3-0.2-0.4
                      c-0.1-0.1-0.2-0.2-0.3-0.3s-0.3-0.4-0.4-0.5c-0.2-0.1-0.5-0.3-0.5-0.3s-0.1-0.5,0-0.7s0.3-0.3,0.4-0.2C56.1,8.6,56.6,8.9,57.2,9
                      L57.2,9z"
                          />
                          <path
                            d="M55.1,27.7c-0.2,0.2-0.4,0.5-0.5,1.1c0,0.5,0.1,0.6,0.2,0.9c0.1,0.2,0.3,0.2,0.4,0c0.1-0.2,0-0.3,0-0.7
                      c0-0.3,0.3-0.4,0.3-0.4v-1.2C55.5,27.4,55.3,27.5,55.1,27.7L55.1,27.7z"
                          />
                        </g>
                        <path
                          class="fiber-bcbs-logo--logo-blue"
                          d="M55.1,10c-0.1,0.1-0.2,0.2-0.4,0.2c-0.3,0-0.2-0.2-0.4-0.3c-0.1-0.1-0.2,0-0.3-0.2C54.4,9.7,54.9,9.7,55.1,10
                      L55.1,10L55.1,10z"
                        />
                        <g class="bcbs-logo--logo-black">
                        <path
                        d="M81.3,10.3h1.3V1.2h-1.3V0.7h5.3c1.8,0,3.1,0.4,3.1,2.1c0,1.6-1.3,2.3-2.7,2.4l0,0c1.7,0,3.3,0.9,3.3,2.8
                    c0,1.6-1.1,2.7-3.5,2.7h-5.6L81.3,10.3L81.3,10.3z M84.8,5.1h0.9c1.2,0,1.7-0.6,1.7-2s-0.4-2-1.8-2h-0.8
                    C84.8,1.1,84.8,5.1,84.8,5.1z M84.8,10.3h0.8c1.6,0,2.4-0.6,2.4-2.5c0-1.6-0.5-2.3-2.1-2.3h-1v4.8H84.8z"
                      />
                      <path d="M90.8,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L90.8,10.3L90.8,10.3z" />
                      <path
                        d="M102.6,10.3h1v0.4c-1.4,0-2.2,0-3,0.1V9.7l0,0c-0.5,0.8-1.4,1.1-2.2,1.1c-1.4,0-2.4-0.5-2.4-2.3V4.3h-1V3.9
                    h0.9c0.7,0,1.4,0,2.2-0.1V9c0,1.1,0.5,1.2,1.1,1.2c0.9,0,1.6-0.7,1.6-2.2V4.3h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5H102.6z"
                      />
                      <path
                        d="M106.3,7v0.6c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1
                    c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2H106.3z M108.8,6.5V5.9c0-0.8-0.2-1.8-1.1-1.8
                    c-1.3,0-1.3,1.5-1.4,2.4H108.8z"
                      />
                      <path
                        d="M120.8,4.2h-0.4c-0.1-1.7-1.5-3.2-3.2-3.2c-2.7,0-3.1,2.8-3.1,4.7c0,1.8,0.2,4.8,3,4.8c1.7,0,3.1-1.5,3.3-3.2
                    h0.4v2.8c-1.1,0.4-2.6,0.8-3.8,0.8c-3.2,0-5.3-2.2-5.3-5.2s2-5.2,5.3-5.2c1.2,0,2.4,0.3,3.7,0.8L120.8,4.2L120.8,4.2z"
                      />
                      <path
                        d="M121.5,10.3h1v-6h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v1.1l0,0c0.3-0.7,1-1.2,1.8-1.2c0.9,0,1.3,0.6,1.3,1.4
                    c0,0.6-0.3,0.9-0.9,0.9c-0.5,0-0.9-0.3-0.9-0.7c0-0.4,0-0.4,0-0.7c0-0.2-0.1-0.3-0.3-0.3c-0.8,0-1.1,1.9-1.1,3v3h1v0.4h-4V10.3z"
                      />
                      <path
                        d="M131.4,10.9c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6C135.1,9.4,133.6,10.9,131.4,10.9z
                    M131.4,4.1c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2s1.5-1.5,1.5-3.2C132.9,5.7,132.9,4.1,131.4,4.1z"
                      />
                      <path
                        d="M135.8,8.2h0.4c0.1,1.3,0.9,2.3,2.1,2.3c0.7,0,1.4-0.3,1.4-1c0-1.6-3.8-0.9-3.8-3.6c0-1.4,1.1-2.2,2.5-2.2
                    c1.1,0,1.6,0.2,2.2,0.4v1.8h-0.4c-0.1-1-0.9-1.8-1.8-1.8c-0.6,0-1,0.3-1,0.9c0,1.4,3.8,0.8,3.8,3.5c0,1.6-1.4,2.4-2.8,2.4
                    c-1.1,0-1.9-0.3-2.5-0.5L135.8,8.2L135.8,8.2z"
                      />
                      <path
                        d="M141.8,8.2h0.4c0.1,1.3,0.9,2.3,2.1,2.3c0.7,0,1.4-0.3,1.4-1c0-1.6-3.8-0.9-3.8-3.6c0-1.4,1.1-2.2,2.5-2.2
                    c1.1,0,1.6,0.2,2.2,0.4v1.8h-0.4c-0.1-1-0.9-1.8-1.8-1.8c-0.6,0-1,0.3-1,0.9c0,1.4,3.8,0.8,3.8,3.5c0,1.6-1.4,2.4-2.8,2.4
                    c-1.1,0-1.9-0.3-2.5-0.5L141.8,8.2L141.8,8.2z"
                      />
                      <path
                        d="M151,10.3h1.3V1.2H151V0.7h5.3c1.8,0,3.1,0.4,3.1,2.1c0,1.6-1.3,2.3-2.7,2.4l0,0c1.7,0,3.3,0.9,3.3,2.8
                    c0,1.6-1.1,2.7-3.5,2.7h-5.6L151,10.3L151,10.3z M154.5,5.1h0.9c1.2,0,1.7-0.5,1.7-1.9s-0.4-2-1.8-2h-0.8L154.5,5.1L154.5,5.1z
                    M154.5,10.3h0.8c1.6,0,2.4-0.6,2.4-2.5c0-1.6-0.5-2.3-2.1-2.3h-1L154.5,10.3L154.5,10.3z"
                      />
                      <path d="M160.5,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L160.5,10.3L160.5,10.3z" />
                      <path
                        d="M172.4,10.3h1v0.4c-1.4,0-2.2,0-3,0.1V9.7l0,0c-0.5,0.8-1.4,1.1-2.2,1.1c-1.4,0-2.4-0.5-2.4-2.3V4.3h-1V3.9
                    h0.9c0.7,0,1.4,0,2.2-0.1V9c0,1.1,0.5,1.2,1.1,1.2c0.9,0,1.6-0.7,1.6-2.2V4.3h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5H172.4z"
                      />
                      <path
                        d="M176,7v0.6c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1
                    c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2H176z M178.6,6.5V5.9c0-0.8-0.2-1.8-1.2-1.8
                    c-1.3,0-1.3,1.5-1.4,2.4H178.6z"
                      />
                      <path
                        d="M181.4,7.4h0.5c0.1,1.4,1.2,3,3,3c1.1,0,2.2-0.4,2.2-1.7c0-2.5-5.5-1.7-5.5-5.2c0-2.1,1.8-3,3.3-3
                    c0.8,0,1.6,0.1,2.8,0.6l0.1,2.4h-0.5C187,2.1,186,1,184.6,1c-0.9,0-1.6,0.6-1.6,1.5c0,2.2,5.4,1.4,5.4,5.3c0,1.8-1.6,3.1-3.6,3.1
                    c-1.1,0-2.2-0.2-3.3-0.7L181.4,7.4z"
                      />
                      <path
                        d="M188.9,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v4.2l0,0c0.5-0.7,1.2-1.1,2.2-1.1c1.4,0,2.4,0.8,2.4,2.3
                    v4.3h1v0.4h-4v-0.4h1V5.7c0-1.1-0.5-1.4-1.1-1.4c-0.8,0-1.6,0.8-1.6,1.9v4h1v0.4h-4L188.9,10.3L188.9,10.3z"
                      />
                      <path
                        d="M197.8,10.3h1v-6h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5h1v0.4h-4L197.8,10.3L197.8,10.3z M199.8,0.6
                    c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C198.7,1.1,199.2,0.6,199.8,0.6z"
                      />
                      <path
                        d="M204.4,7v0.6c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1
                    c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2H204.4z M207,6.5V5.9c0-0.8-0.2-1.8-1.1-1.8
                    c-1.3,0-1.3,1.5-1.4,2.4H207z"
                      />
                      <path d="M209.5,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L209.5,10.3L209.5,10.3z" />
                      <path
                        d="M219.1,9.9L219.1,9.9c-0.5,0.8-1.3,1-2.1,1c-2.1,0-3-1.7-3-3.6s1.1-3.6,3.1-3.6c0.9,0,1.6,0.3,1.9,1l0,0V1.2
                    h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4c-1.4,0-2.2,0-3,0.1L219.1,9.9L219.1,9.9z M217.6,4.3c-1.4,0-1.5,1.3-1.5,3
                    c0,2,0.4,3,1.5,3c1.5,0,1.6-1.7,1.6-3C219.1,6.2,219,4.3,217.6,4.3z"
                      />
                      <path
                        d="M85.1,27.7c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6C88.7,26.2,87.2,27.7,85.1,27.7z
                    M85.1,20.9c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2s1.5-1.5,1.5-3.2C86.6,22.5,86.6,20.9,85.1,20.9z"
                      />
                      <path
                        d="M89.1,27.1h1v-6h-1v-0.4h1v-0.9c0-1.5,0.8-2.5,2.8-2.5c0.6,0,2.3,0.2,2.3,1.5c0,0.7-0.3,1-0.9,1
                    c-0.7,0-0.9-0.4-0.9-0.9c0-0.6,0-1.2-0.6-1.2s-0.7,0.5-0.7,1.1v1.8h1.2v0.5h-1.2v6h1.2v0.4h-4.2C89.1,27.5,89.1,27.1,89.1,27.1z"
                      />
                      <path
                        d="M97.4,27.1h1.4v-9.2h-1.4v-0.4h4l5.1,6.6l0,0v-6.2h-1.6v-0.4h3.4v0.4h-1.4v9.8l-0.3,0.1l-7.4-9.3l0,0v8.6h1.5
                    v0.4h-3.3V27.1z"
                      />
                      <path
                        d="M110.6,23.8v0.6c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1
                    c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2H110.6z M113.2,23.3v-0.6c0-0.8-0.2-1.8-1.1-1.8
                    c-1.3,0-1.3,1.5-1.4,2.4H113.2z"
                      />
                      <path
                        d="M116.4,21.1h-1v-0.4h4.2v0.4h-1.1l1.4,3.9l0,0l1.5-3.9h-1.1v-0.4h4.3v0.4h-1.1l1.3,3.9l0,0l1.4-3.9h-1v-0.4
                    h2.5v0.4h-1l-2.5,6.6h-0.4l-2.2-6l-2.3,6h-0.4L116.4,21.1z"
                      />
                      <path
                        d="M131.1,27.1h1.4v-9.2h-1.4v-0.4h3.8l2.7,7.3l3-7.3h3.7v0.4h-1.4v9.2h1.4v0.4h-5.1v-0.4h1.5v-8.4l0,0l-3.7,9.1
                    h-0.5l-3.6-9.1l0,0v8.4h1.6v0.4h-3.4V27.1z"
                      />
                      <path
                        d="M146.9,23.8v0.6c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1
                    c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2H146.9z M149.4,23.3v-0.6c0-0.8-0.2-1.8-1.1-1.8
                    c-1.3,0-1.3,1.5-1.4,2.4H149.4z"
                      />
                      <path
                        d="M152,27.1h1l2.1-2.6l-2.1-3.4h-1.1v-0.4h4.3v0.4h-0.9l1.1,1.8l1.4-1.8h-0.9v-0.4h2.5v0.4h-1l-1.8,2.2l2.4,3.8
                    h0.9v0.4h-4.3v-0.4h1l-1.4-2.2l-1.7,2.2h1.1v0.4h-2.7v-0.4H152z"
                      />
                      <path
                        d="M160.3,27.1h1v-6h-1v-0.4h0.9c0.7,0,1.4,0,2.2-0.1v6.5h1v0.4h-4L160.3,27.1L160.3,27.1z M162.3,17.4
                    c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1C161.2,17.9,161.7,17.4,162.3,17.4z"
                      />
                      <path
                        d="M171.2,25.5c-0.4,1.4-1.4,2.2-2.9,2.2c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.1,0,2.7,0.3,2.7,1.8
                    c0,0.5-0.3,0.8-0.8,0.8c-0.8,0-1-0.4-1-1.2c0-0.6-0.3-1-0.9-1c-1.5,0-1.5,1.5-1.5,3.2c0,1.3,0.1,3,1.9,3c1.2,0,1.8-0.8,2.2-1.8
                    L171.2,25.5z"
                      />
                      <path
                        d="M175.7,27.7c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6C179.4,26.2,177.9,27.7,175.7,27.7
                    z M175.7,20.9c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2s1.5-1.5,1.5-3.2C177.2,22.5,177.2,20.9,175.7,20.9z"
                      />
                        </g>
                      </svg>
                      <svg *ngIf="imagePath==='ok'"xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 222.1 36.9" aria-hidden="true">
                        <title>Logo for Blue Cross
                        Blue Shield of Oklahoma</title>
                        <desc>Blue Cross
                        Blue Shield of Oklahoma</desc>    
                        <g class="fiber-bcbs-logo--logo-blue">
                              <path
                                d="M29.7,34.8c0.3,0,0.7,0,0.7-0.4c0-0.3-0.3-0.4-0.5-0.4h-0.6v0.7L29.7,34.8L29.7,34.8z M30.6,35.9h-0.3L29.7,35
                          h-0.5v0.9H29v-2h0.9c0.5,0,0.7,0.2,0.7,0.5c0,0.4-0.3,0.6-0.6,0.6L30.6,35.9L30.6,35.9z M31.3,34.9c0-0.9-0.6-1.6-1.5-1.6
                          s-1.5,0.7-1.5,1.6s0.6,1.6,1.5,1.6C30.6,36.5,31.3,35.8,31.3,34.9L31.3,34.9z M27.9,34.9c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8
                          s-0.8,1.8-1.8,1.8S27.9,35.9,27.9,34.9L27.9,34.9z"
                              />
                              <path
                                d="M64,34.8c0.3,0,0.7,0,0.7-0.4c0-0.3-0.3-0.4-0.5-0.4h-0.5v0.7L64,34.8L64,34.8z M65,35.9h-0.3L64.1,35h-0.4
                          v0.9h-0.3v-2h0.9c0.5,0,0.7,0.2,0.7,0.5c0,0.4-0.3,0.6-0.6,0.6L65,35.9L65,35.9z M65.6,34.9c0-0.9-0.6-1.6-1.5-1.6
                          c-0.9,0-1.5,0.7-1.5,1.6s0.7,1.6,1.5,1.6C65,36.5,65.6,35.8,65.6,34.9L65.6,34.9z M62.3,34.9c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8
                          s-0.8,1.8-1.8,1.8C63.1,36.7,62.3,35.9,62.3,34.9L62.3,34.9z"
                              />
                              <polygon
                                points="26.1,36.5 26.1,26.5 36.2,26.5 36.2,10.4 26.1,10.4 26.1,0.4 10.1,0.4 10.1,10.4 0,10.4 0,26.5 
                          10.1,26.5 10.1,36.5 	"
                              />
                              <path
                                d="M56,36.9c6.9-3.8,11.1-9.9,13.3-16.6c2.2-7,1.9-15.2-0.7-20.3c-4,2-8,3-12.6,3s-8.6-1-12.6-3
                          c-2.6,5.1-3,13.4-0.7,20.3C44.8,27,49.1,33.1,56,36.9L56,36.9L56,36.9z"
                              />
                            </g>
                            <g class="fiber-bcbs-logo--logo-white">
                              <path
                                d="M8.8,15l6.7,0.6c1.3,0.2,1.4,1.4,1.1,2.1L12,26.3l0,0c-2.3-1.8-3.8-4.6-3.8-7.8C8.2,17.2,8.4,16.1,8.8,15
                          L8.8,15L8.8,15z"
                              />
                              <path
                                d="M27.4,15l-6.7,0.6c-1.3,0.2-1.4,1.4-1.1,2.1l4.6,8.6l0,0c2.3-1.8,3.8-4.6,3.8-7.8C28.1,17.2,27.8,16.1,27.4,15
                          L27.4,15L27.4,15z"
                              />
                              <path
                                d="M18.1,9.8c1.2,0,1.5,1.3,1,2c-0.5,0.6-0.6,0.8-0.5,1.4c0,0.5,0.5,1.1,1.5,1.1h7.1c-1.6-3.4-5-5.7-9-5.7
                          s-7.4,2.3-9,5.7h7.1c1,0,1.5-0.6,1.5-1.1c0-0.6-0.1-0.8-0.5-1.4C16.6,11.1,17,9.8,18.1,9.8L18.1,9.8z"
                              />
                              <path
                                d="M18.1,28.4c2,0,3.9-0.6,5.5-1.6l0,0l-4.9-7.3c-0.3-0.5-0.9-0.5-1.2,0l-4.8,7.3l0,0
                          C14.2,27.8,16.1,28.4,18.1,28.4"
                              />
                              <path
                                d="M56,33.8c5.7-3.5,8.7-8.6,10.7-14.3c1.7-5.1,2.2-11,0.2-15.7c-2.9,1-4.5,1.3-6.1,1.5c-1.4,0.2-2.7,0.4-4.9,0.4
                          l0,0c-2.1,0-3.4-0.2-4.9-0.4c-1.6-0.2-3.2-0.5-6.1-1.5c-1.9,4.6-1.5,10.6,0.3,15.7C47.2,25.2,50.3,30.3,56,33.8L56,33.8L56,33.8z"
                              />
                            </g>
                            <g class="fiber-bcbs-logo--logo-blue">
                              <path
                                d="M9.8,16.4l5,0.5c0.4,0,0.4,0.2,0.3,0.4L11.6,24c-1.3-1.5-2.1-3.4-2.1-5.6C9.6,17.8,9.7,17.1,9.8,16.4L9.8,16.4
                          z"
                              />
                              <path d="M15.8,10.2c-0.5,0.8-0.1,1.9,0.4,2.5c0.1,0.1,0,0.2,0,0.2h-4.5C12.7,11.7,14.1,10.7,15.8,10.2L15.8,10.2z" />
                              <path
                                d="M26.4,16.4l-5,0.5c-0.4,0-0.4,0.2-0.3,0.4l3.5,6.7c1.3-1.5,2.1-3.4,2.1-5.6C26.7,17.8,26.6,17.1,26.4,16.4
                          L26.4,16.4z"
                              />
                              <path d="M20.5,10.2c0.5,0.8,0.1,1.9-0.4,2.5c-0.1,0.1,0,0.2,0,0.2h4.5C23.6,11.7,22.1,10.7,20.5,10.2L20.5,10.2z" />
                              <path
                                d="M56,32.4c3.9-2.4,8.2-7.9,10-15.2c0.9-3.8,1.2-8.2,0-11.6c-1.7,0.5-3.3,0.8-5,1.1c-1.7,0.2-3.3,0.4-4.9,0.4
                          l0,0c-1.6,0-3.3-0.2-4.9-0.4c-1.7-0.2-3.3-0.6-5-1.1c-1.2,3.4-1,7.8,0,11.6C47.8,24.5,52.1,30,56,32.4L56,32.4L56,32.4z"
                              />
                              <path
                                d="M18,21.2c0.1-0.1,0.2-0.1,0.3,0l3.3,5.1c-1.1,0.5-2.2,0.7-3.5,0.7c-1.2,0-2.4-0.3-3.5-0.7L18,21.2
                          c0.1-0.1,0.2-0.1,0.3,0"
                              />
                            </g>
                            <g class="fiber-bcbs-logo--logo-white">
                              <path
                                d="M52,9.6c-1.3,0.7-1.8,2.3-1.2,3.6c0.5,1.1,1.5,1.9,2.7,2.2c0.6,0.2,1.2,0.2,1.8,0.2c0.7,0,1.4,0.1,2.1,0.2
                          c0.2,0,0.3,0.1,0.4,0.3c0.2,0.4,0,0.7-0.4,0.9c0,0.6-0.1,1.9-0.1,1.9s3-0.3,2.5-3c-0.1-0.7-0.6-1.3-1.2-1.6
                          c-1.5-0.8-3.2-0.4-4.7-0.9c-0.5-0.2-1.3-0.4-1.4-1c-0.1-0.2,0-0.7,0.2-0.8c0.4-0.1,0.8,0.2,1.4,0.3c0.4,0.1,0.7,0.1,1,0
                          c0.1,0,0.3-0.1,0.3-0.2c0-0.1-0.2-0.1-0.2-0.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.3-0.1-0.6-0.3-0.9-0.4c0.3-0.1,0.8-0.1,1.1,0
                          c0.2,0,1,0,1.2,0c0,0,0.2-0.1,0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.3-0.4-0.6-0.7-0.7C54.2,9.2,53,9.1,52,9.6L52,9.6z"
                              />
                              <path
                                d="M57.1,19.4v-3.2c0,0-0.4-0.1-1-0.1c-0.7-0.1-1.1,0-1.1,0v0.3l-0.4,0.2c0,0-0.1,0.2-0.1,0.3
                          c0.3,0.8,0.7,1.5,0.8,2.4C55.9,19.3,56.4,19.3,57.1,19.4L57.1,19.4z"
                              />
                              <path
                                d="M53.3,18.9c-0.1,1.2,0.7,1.9,1.6,2.1c0.7,0.2,1.4,0.2,2.1,0.3c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.1,0.2,0.2
                          s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.3,0.2v1.5c0,0,0.6,0,1.1-0.3s0.7-0.6,0.9-1.1c0.1-0.5,0-1-0.3-1.5c-0.2-0.3-0.7-0.6-1.1-0.7
                          c-0.9-0.3-1.8-0.3-2.8-0.4c-0.1,0-0.3-0.1-0.3-0.3s0.2-0.3,0.2-0.3s-0.1-0.4-0.2-0.7c-0.1-0.3-0.3-0.6-0.4-0.8
                          C53.8,17.7,53.4,18.2,53.3,18.9L53.3,18.9z"
                              />
                              <path d="M57,21.7v2.7l-0.7-0.3l-0.6-0.3v-2.2C56.2,21.6,56.5,21.6,57,21.7L57,21.7z" />
                              <path
                                d="M54,23.1c-0.4,0.9,0.1,1.7,0.8,2.1c0.4,0.2,0.9,0.4,1.3,0.5c0.4,0.2,1.1,0.6,1.2,0.8c0,0.1-0.1,0.3-0.1,0.3
                          v1.1c0,0,0.4-0.1,0.8-0.4c0.3-0.3,0.5-0.6,0.5-1.1c0-0.6-0.3-1-1.1-1.5c-0.6-0.4-1.4-0.7-2-0.9c-0.1,0-0.2-0.2-0.1-0.3
                          c0-0.1,0.1-0.2,0.1-0.2v-1.2c0,0-0.5,0.1-0.7,0.2S54.2,22.8,54,23.1L54,23.1z"
                              />
                              <path
                                d="M56.9,26.7c0-0.1,0-0.1-0.1-0.2c-0.2-0.2-0.4-0.3-0.5-0.4c-0.2-0.1-0.6-0.3-0.6-0.3s0,2.8,0.1,4.2
                          c0,0.3,0.2,0.4,0.5,0.4c0.4,0,0.5-0.2,0.5-0.4C56.8,29.7,56.9,27.8,56.9,26.7L56.9,26.7z"
                              />
                              <path
                                d="M57.2,9c0.1,0.1,0.1,0.1,0.2,0.3c0.1,0.4,0,1.1,0.1,1.4c0,0.1,0.1,0.1,0.2,0.1c0.6-0.3,1-0.9,1.6-1.2
                          c0.3-0.1,1.1,0.3,0.9,0.9c-0.1,0.3-0.2,0.3-0.4,0.5c-0.4,0.4-0.9,0.7-1.3,1.2c-0.3,0.3-0.7,0.6-0.9,0.9c-0.1,0.1-0.2,0.3-0.2,0.3
                          c-0.9-0.2-1.7-0.1-2.4-0.3v-1c0,0,0.7-0.1,0.8-0.4c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c0.7,0.1,1.1,0.2,1.8,0.5
                          c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.1-0.1-0.4-0.2c-0.4-0.1-1.5-0.2-1.5-0.2s0.5,0,0.9-0.1c0.2,0,0.4-0.1,0.4-0.1c0-0.1,0-0.3-0.2-0.4
                          c-0.1-0.1-0.2-0.2-0.3-0.3s-0.3-0.4-0.4-0.5c-0.2-0.1-0.5-0.3-0.5-0.3s-0.1-0.5,0-0.7s0.3-0.3,0.4-0.2C56.1,8.6,56.6,8.9,57.2,9
                          L57.2,9z"
                              />
                              <path
                                d="M55.1,27.7c-0.2,0.2-0.4,0.5-0.5,1.1c0,0.5,0.1,0.6,0.2,0.9c0.1,0.2,0.3,0.2,0.4,0c0.1-0.2,0-0.3,0-0.7
                          c0-0.3,0.3-0.4,0.3-0.4v-1.2C55.5,27.4,55.3,27.5,55.1,27.7L55.1,27.7z"
                              />
                            </g>
                            <path
                              class="fiber-bcbs-logo--logo-blue"
                              d="M55.1,10c-0.1,0.1-0.2,0.2-0.4,0.2c-0.3,0-0.2-0.2-0.4-0.3c-0.1-0.1-0.2,0-0.3-0.2C54.4,9.7,54.9,9.7,55.1,10
                          L55.1,10L55.1,10z"
                            />
                            <g class="bcbs-logo--logo-black">
                            <path
                            d="M81.2,10.3h1.3V1.2h-1.3V0.7h5.3c1.8,0,3.1,0.4,3.1,2.1c0,1.6-1.3,2.3-2.7,2.4l0,0c1.7,0,3.3,0.9,3.3,2.8
                        c0,1.6-1.1,2.7-3.5,2.7h-5.6L81.2,10.3L81.2,10.3z M84.8,5.1h0.9c1.2,0,1.7-0.5,1.7-1.9s-0.4-2-1.8-2h-0.8
                        C84.8,1.2,84.8,5.1,84.8,5.1z M84.8,10.3h0.8c1.6,0,2.4-0.6,2.4-2.5c0-1.6-0.5-2.3-2.1-2.3h-1v4.8H84.8z"
                          />
                          <path d="M90.7,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L90.7,10.3L90.7,10.3z" />
                          <path
                            d="M102.6,10.3h1v0.4c-1.4,0-2.2,0-3,0.1V9.7l0,0c-0.5,0.8-1.4,1.1-2.2,1.1c-1.4,0-2.4-0.5-2.4-2.3V4.3h-1V3.9
                        h0.9c0.7,0,1.4,0,2.2-0.1V9c0,1.1,0.5,1.2,1.1,1.2c0.9,0,1.6-0.7,1.6-2.2V4.3h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5H102.6z"
                          />
                          <path
                            d="M106.3,7v0.6c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1
                        c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2H106.3z M108.8,6.5V5.9c0-0.8-0.2-1.8-1.1-1.8
                        c-1.3,0-1.3,1.5-1.4,2.4H108.8z"
                          />
                          <path
                            d="M120.8,4.2h-0.4c-0.1-1.7-1.5-3.2-3.2-3.2c-2.7,0-3.1,2.8-3.1,4.7c0,1.8,0.2,4.8,3,4.8c1.7,0,3.1-1.5,3.3-3.2
                        h0.4v2.8c-1.1,0.4-2.6,0.8-3.8,0.8c-3.2,0-5.3-2.2-5.3-5.2s2-5.2,5.3-5.2c1.2,0,2.4,0.3,3.7,0.8L120.8,4.2L120.8,4.2z"
                          />
                          <path
                            d="M121.4,10.3h1v-6h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v1.1l0,0c0.3-0.7,1-1.2,1.8-1.2c0.9,0,1.3,0.6,1.3,1.4
                        c0,0.6-0.3,0.9-0.9,0.9c-0.5,0-0.9-0.3-0.9-0.7c0-0.4,0-0.4,0-0.7c0-0.2-0.1-0.3-0.3-0.3c-0.8,0-1.1,1.9-1.1,3v3h1v0.4h-4V10.3z"
                          />
                          <path
                            d="M131.4,10.9c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6S133.5,10.9,131.4,10.9z
                        M131.4,4.1c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2s1.5-1.5,1.5-3.2C132.8,5.7,132.8,4.1,131.4,4.1z"
                          />
                          <path
                            d="M135.8,8.2h0.4c0.1,1.3,0.9,2.3,2.1,2.3c0.7,0,1.4-0.3,1.4-1c0-1.6-3.8-0.9-3.8-3.6c0-1.4,1.1-2.2,2.5-2.2
                        c1.1,0,1.6,0.2,2.2,0.4v1.8h-0.4c-0.1-1-0.9-1.8-1.8-1.8c-0.6,0-1,0.3-1,0.9c0,1.4,3.8,0.8,3.8,3.5c0,1.6-1.4,2.4-2.8,2.4
                        c-1.1,0-1.9-0.3-2.5-0.5L135.8,8.2L135.8,8.2z"
                          />
                          <path
                            d="M141.8,8.2h0.4c0.1,1.3,0.9,2.3,2.1,2.3c0.7,0,1.4-0.3,1.4-1c0-1.6-3.8-0.9-3.8-3.6c0-1.4,1.1-2.2,2.5-2.2
                        c1.1,0,1.6,0.2,2.2,0.4v1.8h-0.4c-0.1-1-0.9-1.8-1.8-1.8c-0.6,0-1,0.3-1,0.9c0,1.4,3.8,0.8,3.8,3.5c0,1.6-1.4,2.4-2.8,2.4
                        c-1.1,0-1.9-0.3-2.5-0.5L141.8,8.2L141.8,8.2z"
                          />
                          <path
                            d="M151,10.3h1.3V1.2H151V0.7h5.3c1.8,0,3.1,0.4,3.1,2.1c0,1.6-1.3,2.3-2.7,2.4l0,0c1.7,0,3.3,0.9,3.3,2.8
                        c0,1.6-1.1,2.7-3.5,2.7H151V10.3z M154.5,5.1h0.9c1.2,0,1.7-0.5,1.7-1.9s-0.4-2-1.8-2h-0.8L154.5,5.1L154.5,5.1z M154.5,10.3h0.8
                        c1.6,0,2.4-0.6,2.4-2.5c0-1.6-0.5-2.3-2.1-2.3h-1L154.5,10.3L154.5,10.3z"
                          />
                          <path d="M160.5,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L160.5,10.3L160.5,10.3z" />
                          <path
                            d="M172.4,10.3h1v0.4c-1.4,0-2.2,0-3,0.1V9.7l0,0c-0.5,0.8-1.4,1.1-2.2,1.1c-1.4,0-2.4-0.5-2.4-2.3V4.3h-1V3.9
                        h0.9c0.7,0,1.4,0,2.2-0.1V9c0,1.1,0.5,1.2,1.1,1.2c0.9,0,1.6-0.7,1.6-2.2V4.3h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5H172.4z"
                          />
                          <path
                            d="M176,7v0.6c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1
                        c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2H176z M178.5,6.5V5.9c0-0.8-0.2-1.8-1.1-1.8
                        c-1.3,0-1.3,1.5-1.4,2.4H178.5z"
                          />
                          <path
                            d="M181.4,7.4h0.5c0.1,1.4,1.2,3,3,3c1.1,0,2.2-0.4,2.2-1.7c0-2.5-5.5-1.7-5.5-5.2c0-2.1,1.8-3,3.3-3
                        c0.8,0,1.6,0.1,2.8,0.6l0.1,2.4h-0.5c-0.3-1.3-1.3-2.4-2.7-2.4c-0.9,0-1.6,0.6-1.6,1.5c0,2.2,5.4,1.4,5.4,5.3
                        c0,1.8-1.6,3.1-3.6,3.1c-1.1,0-2.2-0.2-3.3-0.7L181.4,7.4z"
                          />
                          <path
                            d="M188.9,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v4.2l0,0c0.5-0.7,1.2-1.1,2.2-1.1c1.4,0,2.4,0.8,2.4,2.3
                        v4.3h1v0.4h-4v-0.4h1V5.7c0-1.1-0.5-1.4-1.1-1.4c-0.8,0-1.6,0.8-1.6,1.9v4h1v0.4h-4L188.9,10.3L188.9,10.3z"
                          />
                          <path
                            d="M197.7,10.3h1v-6h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5h1v0.4h-4L197.7,10.3L197.7,10.3z M199.8,0.6
                        c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1S199.1,0.6,199.8,0.6z"
                          />
                          <path
                            d="M204.4,7v0.6c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1
                        c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2H204.4z M206.9,6.5V5.9c0-0.8-0.2-1.8-1.1-1.8
                        c-1.3,0-1.3,1.5-1.4,2.4H206.9z"
                          />
                          <path d="M209.5,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L209.5,10.3L209.5,10.3z" />
                          <path
                            d="M219.1,9.9L219.1,9.9c-0.5,0.8-1.3,1-2.1,1c-2.1,0-3-1.7-3-3.6s1.1-3.6,3.1-3.6c0.9,0,1.6,0.3,1.9,1l0,0V1.2
                        h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4c-1.4,0-2.2,0-3,0.1L219.1,9.9L219.1,9.9z M217.5,4.3c-1.4,0-1.5,1.3-1.5,3
                        c0,2,0.4,3,1.5,3c1.5,0,1.6-1.7,1.6-3C219.1,6.2,219,4.3,217.5,4.3z"
                          />
                          <path
                            d="M85.1,27.7c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6S87.2,27.7,85.1,27.7z M85.1,20.9
                        c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2s1.5-1.5,1.5-3.2C86.5,22.5,86.5,20.9,85.1,20.9z"
                          />
                          <path
                            d="M89.1,27.1h1v-6h-1v-0.4h1v-0.9c0-1.5,0.8-2.5,2.8-2.5c0.6,0,2.3,0.2,2.3,1.5c0,0.7-0.3,1-0.9,1
                        c-0.7,0-0.9-0.4-0.9-0.9c0-0.6,0-1.2-0.6-1.2s-0.7,0.5-0.7,1.1v1.8h1.2v0.5h-1.2v6h1.2v0.4h-4.2C89.1,27.5,89.1,27.1,89.1,27.1z"
                          />
                          <path
                            d="M102.8,17.4c3.2,0,5.3,2.2,5.3,5.2s-2,5.2-5.3,5.2c-3.2,0-5.3-2.2-5.3-5.2C97.5,19.5,99.6,17.4,102.8,17.4z
                        M102.8,27.3c2.6,0,2.8-3,2.8-4.8s-0.2-4.7-2.8-4.7s-2.8,3-2.8,4.7C100,24.3,100.2,27.3,102.8,27.3z"
                          />
                          <path
                            d="M108.7,27.1h1v-9.2h-1v-0.4h0.9c0.7,0,1.4,0,2.2-0.1v6.4h0.8l1.6-2.7h-1.1v-0.4h2.9v0.4h-1.2l-1,1.7l2.9,4.4
                        h0.7v0.4h-4v-0.5h0.9l-1.9-2.9h-0.6v2.9h1v0.4h-4v-0.4H108.7z"
                          />
                          <path d="M117.6,27.1h1v-9.2h-1v-0.4h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L117.6,27.1L117.6,27.1z" />
                          <path
                            d="M128.6,25.9c0,0.9,0.1,1.2,1,1.2v0.4c-0.5,0-1.1,0.1-1.6,0.1c-0.8,0-1.3-0.2-1.4-1l0,0
                        c-0.3,0.9-1.4,1.1-2.3,1.1c-1.4,0-2.1-0.9-2.1-2.2c0-1.7,1.3-1.8,3-2.2c0.3-0.1,1-0.3,1.4-0.4V22c0-0.6-0.2-1.1-1.2-1.1
                        c-1.9,0-0.4,1.9-1.9,1.9c-0.4,0-0.8-0.2-0.8-0.8c0-1.2,1.7-1.5,2.7-1.5c1.9,0,3.3,0.2,3.3,2.1L128.6,25.9L128.6,25.9z M126.6,23.3
                        l-1.2,0.4c-0.8,0.3-1.2,0.7-1.2,1.9c0,0.7,0.3,1.3,1,1.3c1.3,0,1.3-1.7,1.3-2.5L126.6,23.3L126.6,23.3z"
                          />
                          <path
                            d="M129.8,27.1h1v-9.2h-1v-0.4h0.9c0.7,0,1.4,0,2.2-0.1v4.2l0,0c0.5-0.7,1.2-1.1,2.2-1.1c1.4,0,2.4,0.8,2.4,2.3
                        v4.3h1v0.4h-4v-0.4h1v-4.6c0-1.1-0.5-1.4-1.1-1.4c-0.8,0-1.6,0.8-1.6,1.9v4h1v0.4h-4L129.8,27.1L129.8,27.1z"
                          />
                          <path
                            d="M142.6,27.7c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6C146.3,26.2,144.8,27.7,142.6,27.7
                        z M142.6,20.9c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2s1.5-1.5,1.5-3.2C144.1,22.5,144.1,20.9,142.6,20.9z"
                          />
                          <path
                            d="M146.9,27.1h1v-6h-1v-0.4h0.9c0.7,0,1.4,0,2.2-0.1v1.1l0,0c0.5-0.8,1.4-1.1,2.2-1.1c1.2,0,2,0.3,2.3,1.3l0,0
                        c0.4-1,1.5-1.3,2.3-1.3c1.4,0,2.4,0.5,2.4,2.3v4.2h1v0.4h-4v-0.4h1v-4.7c0-1.1-0.5-1.3-1.1-1.3c-0.9,0-1.6,0.7-1.6,2.2v3.8h1v0.4
                        h-4v-0.4h1v-4.7c0-1.1-0.5-1.3-1.1-1.3c-0.9,0-1.6,0.7-1.6,2.2v3.8h1v0.4h-4v-0.4H146.9z"
                          />
                          <path
                            d="M167.3,25.9c0,0.9,0.1,1.2,1,1.2v0.4c-0.5,0-1.1,0.1-1.6,0.1c-0.8,0-1.3-0.2-1.4-1l0,0
                        c-0.3,0.9-1.4,1.1-2.3,1.1c-1.4,0-2.1-0.9-2.1-2.2c0-1.7,1.3-1.8,3-2.2c0.3-0.1,1-0.3,1.4-0.4V22c0-0.6-0.2-1.1-1.2-1.1
                        c-1.9,0-0.4,1.9-1.9,1.9c-0.4,0-0.8-0.2-0.8-0.8c0-1.2,1.7-1.5,2.7-1.5c1.9,0,3.3,0.2,3.3,2.1L167.3,25.9L167.3,25.9z M165.2,23.3
                        l-1.2,0.4c-0.8,0.3-1.2,0.7-1.2,1.9c0,0.7,0.3,1.3,1,1.3c1.3,0,1.3-1.7,1.3-2.5L165.2,23.3L165.2,23.3z"
                          />
                            </g>
                          </svg>
                          <svg *ngIf="imagePath==='tx'"xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 222.1 36.9" aria-hidden="true">
    
                            <title>Logo for Blue Cross
                            Blue Shield of Texas</title>
                            <desc>Blue Cross
                            Blue Shield of Texas</desc><g class="fiber-bcbs-logo--logo-blue">
                              <path
                                d="M29.7,34.8c0.3,0,0.7,0,0.7-0.4c0-0.3-0.3-0.4-0.5-0.4h-0.6v0.7L29.7,34.8L29.7,34.8z M30.6,35.9h-0.3L29.7,35
                          h-0.5v0.9H29v-2h0.9c0.5,0,0.7,0.2,0.7,0.5c0,0.4-0.3,0.6-0.6,0.6L30.6,35.9L30.6,35.9z M31.3,34.9c0-0.9-0.6-1.6-1.5-1.6
                          s-1.5,0.7-1.5,1.6s0.6,1.6,1.5,1.6C30.6,36.5,31.3,35.8,31.3,34.9L31.3,34.9z M27.9,34.9c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8
                          s-0.8,1.8-1.8,1.8S27.9,35.9,27.9,34.9L27.9,34.9z"
                              />
                              <path
                                d="M64,34.8c0.3,0,0.7,0,0.7-0.4c0-0.3-0.3-0.4-0.5-0.4h-0.5v0.7L64,34.8L64,34.8z M65,35.9h-0.3L64.1,35h-0.4
                          v0.9h-0.3v-2h0.9c0.5,0,0.7,0.2,0.7,0.5c0,0.4-0.3,0.6-0.6,0.6L65,35.9L65,35.9z M65.6,34.9c0-0.9-0.6-1.6-1.5-1.6
                          c-0.9,0-1.5,0.7-1.5,1.6s0.7,1.6,1.5,1.6C65,36.5,65.6,35.8,65.6,34.9L65.6,34.9z M62.3,34.9c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8
                          s-0.8,1.8-1.8,1.8C63.1,36.7,62.3,35.9,62.3,34.9L62.3,34.9z"
                              />
                              <polygon
                                points="26.1,36.5 26.1,26.5 36.2,26.5 36.2,10.4 26.1,10.4 26.1,0.4 10.1,0.4 10.1,10.4 0,10.4 0,26.5 
                          10.1,26.5 10.1,36.5 	"
                              />
                              <path
                                d="M56,36.9c6.9-3.8,11.1-9.9,13.3-16.6c2.2-7,1.9-15.2-0.7-20.3c-4,2-8,3-12.6,3s-8.6-1-12.6-3
                          c-2.6,5.1-3,13.4-0.7,20.3C44.8,27,49.1,33.1,56,36.9L56,36.9L56,36.9z"
                              />
                            </g>
                            <g class="fiber-bcbs-logo--logo-white">
                              <path
                                d="M8.8,15l6.7,0.6c1.3,0.2,1.4,1.4,1.1,2.1L12,26.3l0,0c-2.3-1.8-3.8-4.6-3.8-7.8C8.2,17.2,8.4,16.1,8.8,15
                          L8.8,15L8.8,15z"
                              />
                              <path
                                d="M27.4,15l-6.7,0.6c-1.3,0.2-1.4,1.4-1.1,2.1l4.6,8.6l0,0c2.3-1.8,3.8-4.6,3.8-7.8C28.1,17.2,27.8,16.1,27.4,15
                          L27.4,15L27.4,15z"
                              />
                              <path
                                d="M18.1,9.8c1.2,0,1.5,1.3,1,2c-0.5,0.6-0.6,0.8-0.5,1.4c0,0.5,0.5,1.1,1.5,1.1h7.1c-1.6-3.4-5-5.7-9-5.7
                          s-7.4,2.3-9,5.7h7.1c1,0,1.5-0.6,1.5-1.1c0-0.6-0.1-0.8-0.5-1.4C16.6,11.1,17,9.8,18.1,9.8L18.1,9.8z"
                              />
                              <path
                                d="M18.1,28.4c2,0,3.9-0.6,5.5-1.6l0,0l-4.9-7.3c-0.3-0.5-0.9-0.5-1.2,0l-4.8,7.3l0,0
                          C14.2,27.8,16.1,28.4,18.1,28.4"
                              />
                              <path
                                d="M56,33.8c5.7-3.5,8.7-8.6,10.7-14.3c1.7-5.1,2.2-11,0.2-15.7c-2.9,1-4.5,1.3-6.1,1.5c-1.4,0.2-2.7,0.4-4.9,0.4
                          l0,0c-2.1,0-3.4-0.2-4.9-0.4c-1.6-0.2-3.2-0.5-6.1-1.5c-1.9,4.6-1.5,10.6,0.3,15.7C47.2,25.2,50.3,30.3,56,33.8L56,33.8L56,33.8z"
                              />
                            </g>
                            <g class="fiber-bcbs-logo--logo-blue">
                              <path
                                d="M9.8,16.4l5,0.5c0.4,0,0.4,0.2,0.3,0.4L11.6,24c-1.3-1.5-2.1-3.4-2.1-5.6C9.6,17.8,9.7,17.1,9.8,16.4L9.8,16.4
                          z"
                              />
                              <path d="M15.8,10.2c-0.5,0.8-0.1,1.9,0.4,2.5c0.1,0.1,0,0.2,0,0.2h-4.5C12.7,11.7,14.1,10.7,15.8,10.2L15.8,10.2z" />
                              <path
                                d="M26.4,16.4l-5,0.5c-0.4,0-0.4,0.2-0.3,0.4l3.5,6.7c1.3-1.5,2.1-3.4,2.1-5.6C26.7,17.8,26.6,17.1,26.4,16.4
                          L26.4,16.4z"
                              />
                              <path d="M20.5,10.2c0.5,0.8,0.1,1.9-0.4,2.5c-0.1,0.1,0,0.2,0,0.2h4.5C23.6,11.7,22.1,10.7,20.5,10.2L20.5,10.2z" />
                              <path
                                d="M56,32.4c3.9-2.4,8.2-7.9,10-15.2c0.9-3.8,1.2-8.2,0-11.6c-1.7,0.5-3.3,0.8-5,1.1c-1.7,0.2-3.3,0.4-4.9,0.4
                          l0,0c-1.6,0-3.3-0.2-4.9-0.4c-1.7-0.2-3.3-0.6-5-1.1c-1.2,3.4-1,7.8,0,11.6C47.8,24.5,52.1,30,56,32.4L56,32.4L56,32.4z"
                              />
                              <path
                                d="M18,21.2c0.1-0.1,0.2-0.1,0.3,0l3.3,5.1c-1.1,0.5-2.2,0.7-3.5,0.7c-1.2,0-2.4-0.3-3.5-0.7L18,21.2
                          c0.1-0.1,0.2-0.1,0.3,0"
                              />
                            </g>
                            <g class="fiber-bcbs-logo--logo-white">
                              <path
                                d="M52,9.6c-1.3,0.7-1.8,2.3-1.2,3.6c0.5,1.1,1.5,1.9,2.7,2.2c0.6,0.2,1.2,0.2,1.8,0.2c0.7,0,1.4,0.1,2.1,0.2
                          c0.2,0,0.3,0.1,0.4,0.3c0.2,0.4,0,0.7-0.4,0.9c0,0.6-0.1,1.9-0.1,1.9s3-0.3,2.5-3c-0.1-0.7-0.6-1.3-1.2-1.6
                          c-1.5-0.8-3.2-0.4-4.7-0.9c-0.5-0.2-1.3-0.4-1.4-1c-0.1-0.2,0-0.7,0.2-0.8c0.4-0.1,0.8,0.2,1.4,0.3c0.4,0.1,0.7,0.1,1,0
                          c0.1,0,0.3-0.1,0.3-0.2c0-0.1-0.2-0.1-0.2-0.2c-0.2-0.1-0.4-0.1-0.5-0.2c-0.3-0.1-0.6-0.3-0.9-0.4c0.3-0.1,0.8-0.1,1.1,0
                          c0.2,0,1,0,1.2,0c0,0,0.2-0.1,0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.3-0.4-0.6-0.7-0.7C54.2,9.2,53,9.1,52,9.6L52,9.6z"
                              />
                              <path
                                d="M57.1,19.4v-3.2c0,0-0.4-0.1-1-0.1c-0.7-0.1-1.1,0-1.1,0v0.3l-0.4,0.2c0,0-0.1,0.2-0.1,0.3
                          c0.3,0.8,0.7,1.5,0.8,2.4C55.9,19.3,56.4,19.3,57.1,19.4L57.1,19.4z"
                              />
                              <path
                                d="M53.3,18.9c-0.1,1.2,0.7,1.9,1.6,2.1c0.7,0.2,1.4,0.2,2.1,0.3c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.2,0.1,0.2,0.2
                          s0,0.2-0.1,0.3c-0.1,0.1-0.3,0.2-0.3,0.2v1.5c0,0,0.6,0,1.1-0.3s0.7-0.6,0.9-1.1c0.1-0.5,0-1-0.3-1.5c-0.2-0.3-0.7-0.6-1.1-0.7
                          c-0.9-0.3-1.8-0.3-2.8-0.4c-0.1,0-0.3-0.1-0.3-0.3s0.2-0.3,0.2-0.3s-0.1-0.4-0.2-0.7c-0.1-0.3-0.3-0.6-0.4-0.8
                          C53.8,17.7,53.4,18.2,53.3,18.9L53.3,18.9z"
                              />
                              <path d="M57,21.7v2.7l-0.7-0.3l-0.6-0.3v-2.2C56.2,21.6,56.5,21.6,57,21.7L57,21.7z" />
                              <path
                                d="M54,23.1c-0.4,0.9,0.1,1.7,0.8,2.1c0.4,0.2,0.9,0.4,1.3,0.5c0.4,0.2,1.1,0.6,1.2,0.8c0,0.1-0.1,0.3-0.1,0.3
                          v1.1c0,0,0.4-0.1,0.8-0.4c0.3-0.3,0.5-0.6,0.5-1.1c0-0.6-0.3-1-1.1-1.5c-0.6-0.4-1.4-0.7-2-0.9c-0.1,0-0.2-0.2-0.1-0.3
                          c0-0.1,0.1-0.2,0.1-0.2v-1.2c0,0-0.5,0.1-0.7,0.2S54.2,22.8,54,23.1L54,23.1z"
                              />
                              <path
                                d="M56.9,26.7c0-0.1,0-0.1-0.1-0.2c-0.2-0.2-0.4-0.3-0.5-0.4c-0.2-0.1-0.6-0.3-0.6-0.3s0,2.8,0.1,4.2
                          c0,0.3,0.2,0.4,0.5,0.4c0.4,0,0.5-0.2,0.5-0.4C56.8,29.7,56.9,27.8,56.9,26.7L56.9,26.7z"
                              />
                              <path
                                d="M57.2,9c0.1,0.1,0.1,0.1,0.2,0.3c0.1,0.4,0,1.1,0.1,1.4c0,0.1,0.1,0.1,0.2,0.1c0.6-0.3,1-0.9,1.6-1.2
                          c0.3-0.1,1.1,0.3,0.9,0.9c-0.1,0.3-0.2,0.3-0.4,0.5c-0.4,0.4-0.9,0.7-1.3,1.2c-0.3,0.3-0.7,0.6-0.9,0.9c-0.1,0.1-0.2,0.3-0.2,0.3
                          c-0.9-0.2-1.7-0.1-2.4-0.3v-1c0,0,0.7-0.1,0.8-0.4c0,0,0-0.1-0.1-0.2c-0.1-0.1-0.3-0.2-0.4-0.2c0.7,0.1,1.1,0.2,1.8,0.5
                          c0-0.1,0-0.2,0-0.3c-0.1-0.1-0.1-0.1-0.4-0.2c-0.4-0.1-1.5-0.2-1.5-0.2s0.5,0,0.9-0.1c0.2,0,0.4-0.1,0.4-0.1c0-0.1,0-0.3-0.2-0.4
                          c-0.1-0.1-0.2-0.2-0.3-0.3s-0.3-0.4-0.4-0.5c-0.2-0.1-0.5-0.3-0.5-0.3s-0.1-0.5,0-0.7s0.3-0.3,0.4-0.2C56.1,8.6,56.6,8.9,57.2,9
                          L57.2,9z"
                              />
                              <path
                                d="M55.1,27.7c-0.2,0.2-0.4,0.5-0.5,1.1c0,0.5,0.1,0.6,0.2,0.9c0.1,0.2,0.3,0.2,0.4,0c0.1-0.2,0-0.3,0-0.7
                          c0-0.3,0.3-0.4,0.3-0.4v-1.2C55.5,27.4,55.3,27.5,55.1,27.7L55.1,27.7z"
                              />
                            </g>
                            <path
                              class="fiber-bcbs-logo--logo-blue"
                              d="M55.1,10c-0.1,0.1-0.2,0.2-0.4,0.2c-0.3,0-0.2-0.2-0.4-0.3c-0.1-0.1-0.2,0-0.3-0.2C54.4,9.7,54.9,9.7,55.1,10
                          L55.1,10L55.1,10z"
                            />
                            <g class="bcbs-logo--logo-black">
                            <path
                            d="M81.3,10.3h1.3V1.2h-1.3V0.7h5.3c1.8,0,3.1,0.4,3.1,2.1c0,1.6-1.3,2.3-2.7,2.4l0,0c1.7,0,3.3,0.9,3.3,2.8
                        c0,1.6-1.1,2.7-3.5,2.7h-5.6L81.3,10.3L81.3,10.3z M84.8,5.1h0.9c1.2,0,1.7-0.5,1.7-1.9s-0.4-2-1.8-2h-0.8
                        C84.8,1.2,84.8,5.1,84.8,5.1z M84.8,10.3h0.8c1.6,0,2.4-0.6,2.4-2.5c0-1.6-0.5-2.3-2.1-2.3h-1v4.8H84.8z"
                          />
                          <path d="M90.8,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L90.8,10.3L90.8,10.3z" />
                          <path
                            d="M102.7,10.3h1v0.4c-1.4,0-2.2,0-3,0.1V9.7l0,0c-0.5,0.8-1.4,1.1-2.2,1.1c-1.4,0-2.4-0.5-2.4-2.3V4.3h-1V3.9
                        H96c0.7,0,1.4,0,2.2-0.1V9c0,1.1,0.5,1.2,1.1,1.2c0.9,0,1.6-0.7,1.6-2.2V4.3h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5H102.7z"
                          />
                          <path
                            d="M106.4,7v0.6c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1
                        c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2H106.4z M108.9,6.5V5.9c0-0.8-0.2-1.8-1.1-1.8
                        c-1.3,0-1.3,1.5-1.4,2.4H108.9z"
                          />
                          <path
                            d="M120.9,4.2h-0.4C120.4,2.5,119,1,117.3,1c-2.7,0-3.1,2.8-3.1,4.7c0,1.8,0.2,4.8,3,4.8c1.7,0,3.1-1.5,3.3-3.2
                        h0.4v2.8c-1.1,0.4-2.6,0.8-3.8,0.8c-3.2,0-5.3-2.2-5.3-5.2s2-5.2,5.3-5.2c1.2,0,2.4,0.3,3.7,0.8L120.9,4.2L120.9,4.2z"
                          />
                          <path
                            d="M121.5,10.3h1v-6h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v1.1l0,0c0.3-0.7,1-1.2,1.8-1.2c0.9,0,1.3,0.6,1.3,1.4
                        c0,0.6-0.3,0.9-0.9,0.9c-0.5,0-0.9-0.3-0.9-0.7c0-0.4,0-0.4,0-0.7c0-0.2-0.1-0.3-0.3-0.3c-0.8,0-1.1,1.9-1.1,3v3h1v0.4h-4
                        C121.5,10.7,121.5,10.3,121.5,10.3z"
                          />
                          <path
                            d="M131.5,10.9c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6C135.1,9.4,133.6,10.9,131.5,10.9z
                        M131.5,4.1c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2S133,9,133,7.3C132.9,5.7,132.9,4.1,131.5,4.1z"
                          />
                          <path
                            d="M135.8,8.2h0.4c0.1,1.3,0.9,2.3,2.1,2.3c0.7,0,1.4-0.3,1.4-1c0-1.6-3.8-0.9-3.8-3.6c0-1.4,1.1-2.2,2.5-2.2
                        c1.1,0,1.6,0.2,2.2,0.4v1.8h-0.4c-0.1-1-0.9-1.8-1.8-1.8c-0.6,0-1,0.3-1,0.9c0,1.4,3.8,0.8,3.8,3.5c0,1.6-1.4,2.4-2.8,2.4
                        c-1.1,0-1.9-0.3-2.5-0.5L135.8,8.2L135.8,8.2z"
                          />
                          <path
                            d="M141.8,8.2h0.4c0.1,1.3,0.9,2.3,2.1,2.3c0.7,0,1.4-0.3,1.4-1c0-1.6-3.8-0.9-3.8-3.6c0-1.4,1.1-2.2,2.5-2.2
                        c1.1,0,1.6,0.2,2.2,0.4v1.8h-0.4c-0.1-1-0.9-1.8-1.8-1.8c-0.6,0-1,0.3-1,0.9c0,1.4,3.8,0.8,3.8,3.5c0,1.6-1.4,2.4-2.8,2.4
                        c-1.1,0-1.9-0.3-2.5-0.5L141.8,8.2L141.8,8.2z"
                          />
                          <path
                            d="M151.1,10.3h1.3V1.2h-1.3V0.7h5.3c1.8,0,3.1,0.4,3.1,2.1c0,1.6-1.3,2.3-2.7,2.4l0,0c1.7,0,3.3,0.9,3.3,2.8
                        c0,1.6-1.1,2.7-3.5,2.7H151L151.1,10.3L151.1,10.3z M154.6,5.1h0.9c1.2,0,1.7-0.5,1.7-1.9s-0.4-2-1.8-2h-0.8L154.6,5.1L154.6,5.1z
                        M154.6,10.3h0.8c1.6,0,2.4-0.6,2.4-2.5c0-1.6-0.5-2.3-2.1-2.3h-1L154.6,10.3L154.6,10.3z"
                          />
                          <path d="M160.6,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L160.6,10.3L160.6,10.3z" />
                          <path
                            d="M172.4,10.3h1v0.4c-1.4,0-2.2,0-3,0.1V9.7l0,0c-0.5,0.8-1.4,1.1-2.2,1.1c-1.4,0-2.4-0.5-2.4-2.3V4.3h-1V3.9
                        h0.9c0.7,0,1.4,0,2.2-0.1V9c0,1.1,0.5,1.2,1.1,1.2c0.9,0,1.6-0.7,1.6-2.2V4.3h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5H172.4z"
                          />
                          <path
                            d="M176.1,7v0.6c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1
                        c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2H176.1z M178.6,6.5V5.9c0-0.8-0.2-1.8-1.1-1.8
                        c-1.3,0-1.3,1.5-1.4,2.4H178.6z"
                          />
                          <path
                            d="M181.5,7.4h0.5c0.1,1.4,1.2,3,3,3c1.1,0,2.2-0.4,2.2-1.7c0-2.5-5.5-1.7-5.5-5.2c0-2.1,1.8-3,3.3-3
                        c0.8,0,1.6,0.1,2.8,0.6l0.1,2.4h-0.5c-0.3-1.4-1.3-2.5-2.7-2.5c-0.9,0-1.6,0.6-1.6,1.5c0,2.2,5.4,1.4,5.4,5.3
                        c0,1.8-1.6,3.1-3.6,3.1c-1.1,0-2.2-0.2-3.3-0.7L181.5,7.4z"
                          />
                          <path
                            d="M189,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v4.2l0,0c0.5-0.7,1.2-1.1,2.2-1.1c1.4,0,2.4,0.8,2.4,2.3v4.3
                        h1v0.4h-4v-0.4h1V5.7c0-1.1-0.5-1.4-1.1-1.4c-0.8,0-1.6,0.8-1.6,1.9v4h1v0.4h-4L189,10.3L189,10.3z"
                          />
                          <path
                            d="M197.8,10.3h1v-6h-1V3.9h0.9c0.7,0,1.4,0,2.2-0.1v6.5h1v0.4h-4L197.8,10.3L197.8,10.3z M199.8,0.6
                        c0.6,0,1.1,0.5,1.1,1.1s-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1S199.2,0.6,199.8,0.6z"
                          />
                          <path
                            d="M204.5,7v0.6c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1
                        c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2H204.5z M207,6.5V5.9c0-0.8-0.2-1.8-1.1-1.8
                        c-1.3,0-1.3,1.5-1.4,2.4H207z"
                          />
                          <path d="M209.5,10.3h1V1.2h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4h-4L209.5,10.3L209.5,10.3z" />
                          <path
                            d="M219.1,9.9L219.1,9.9c-0.5,0.8-1.3,1-2.1,1c-2.1,0-3-1.7-3-3.6s1.1-3.6,3.1-3.6c0.9,0,1.6,0.3,1.9,1l0,0V1.2
                        h-1V0.7h0.9c0.7,0,1.4,0,2.2-0.1v9.7h1v0.4c-1.4,0-2.2,0-3,0.1L219.1,9.9L219.1,9.9z M217.6,4.3c-1.4,0-1.5,1.3-1.5,3
                        c0,2,0.4,3,1.5,3c1.5,0,1.6-1.7,1.6-3C219.2,6.2,219.1,4.3,217.6,4.3z"
                          />
                          <path
                            d="M85.2,27.7c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6s3.6,1.5,3.6,3.6S87.3,27.7,85.2,27.7z M85.2,20.9
                        c-1.5,0-1.5,1.5-1.5,3.2c0,1.6,0,3.2,1.5,3.2s1.5-1.5,1.5-3.2C86.6,22.5,86.6,20.9,85.2,20.9z"
                          />
                          <path
                            d="M89.1,27.1h1v-6h-1v-0.4h1v-0.9c0-1.5,0.8-2.5,2.8-2.5c0.6,0,2.3,0.2,2.3,1.5c0,0.7-0.3,1-0.9,1
                        c-0.7,0-0.9-0.4-0.9-0.9c0-0.6,0-1.2-0.6-1.2s-0.7,0.5-0.7,1.1v1.8h1.2v0.5h-1.1v6h1.2v0.4h-4.2L89.1,27.1L89.1,27.1z"
                          />
                          <path
                            d="M100,27.1h1.3v-9.2h-0.5c-2.1,0-2.8,1.5-2.9,3h-0.4v-3.4h9.8v3.4h-0.4c0-1.4-0.8-3-2.9-3h-0.5v9.2h1.3v0.4
                        h-4.9L100,27.1L100,27.1z"
                          />
                          <path
                            d="M109.8,23.8v0.6c0,1.2,0.2,2.8,1.8,2.8c1.2,0,1.9-0.7,2.4-1.7l0.4,0.2c-0.6,1.4-1.7,2.1-3.1,2.1
                        c-2.2,0-3.6-1.4-3.6-3.6c0-2.1,1.5-3.6,3.6-3.6c1.8,0,3.2,1.2,3.2,3.2H109.8z M112.3,23.3v-0.6c0-0.8-0.2-1.8-1.1-1.8
                        c-1.3,0-1.3,1.5-1.4,2.4H112.3z"
                          />
                          <path
                            d="M115,27.1h1l2.1-2.6l-2.1-3.4h-1.1v-0.4h4.3v0.4h-0.9l1.1,1.8l1.4-1.8h-0.9v-0.4h2.5v0.4h-0.9l-1.8,2.2
                        l2.4,3.8h0.9v0.4h-4.3v-0.4h1l-1.4-2.2l-1.7,2.2h1.1v0.4H115V27.1z"
                          />
                          <path
                            d="M129.9,25.9c0,0.9,0.1,1.2,1,1.2v0.4c-0.5,0-1.1,0.1-1.6,0.1c-0.8,0-1.3-0.2-1.4-1l0,0
                        c-0.3,0.9-1.4,1.1-2.3,1.1c-1.4,0-2.1-0.9-2.1-2.2c0-1.7,1.3-1.8,3-2.2c0.3-0.1,1-0.3,1.4-0.4V22c0-0.6-0.2-1.1-1.2-1.1
                        c-1.9,0-0.4,1.9-1.9,1.9c-0.4,0-0.8-0.2-0.8-0.8c0-1.2,1.7-1.5,2.7-1.5c1.9,0,3.3,0.2,3.3,2.1L129.9,25.9L129.9,25.9z M127.9,23.3
                        l-1.2,0.4c-0.8,0.3-1.2,0.7-1.2,1.9c0,0.7,0.3,1.3,1,1.3c1.3,0,1.3-1.7,1.3-2.5L127.9,23.3L127.9,23.3z"
                          />
                          <path
                            d="M131.7,25h0.4c0.1,1.3,0.9,2.3,2.1,2.3c0.7,0,1.4-0.3,1.4-1c0-1.6-3.8-0.9-3.8-3.6c0-1.4,1.1-2.2,2.5-2.2
                        c1.1,0,1.6,0.2,2.2,0.4v1.8h-0.4c-0.1-1-0.9-1.8-1.8-1.8c-0.6,0-1,0.3-1,0.9c0,1.4,3.8,0.8,3.8,3.5c0,1.6-1.4,2.4-2.8,2.4
                        c-1.1,0-1.9-0.3-2.5-0.5L131.7,25L131.7,25z"
                          />
                            </g>
                          </svg> -->
        </div>
      </div>
    </div>
  </div>
</header>
